import { selectElectricityTotalConsumptionPerMeter, selectElectricityAvgConsumptionPerMeter } from '.';

/**
 *
 * @param {array} meters - use selectMeters()
 * @param {string} timestamp  - any timestamp in the day
 */
export const selectElectricityTotalConsumption = (meters) => {
  if (!meters.length) return 0;

  const totalPerMeter = selectElectricityTotalConsumptionPerMeter(meters);

  return totalPerMeter.reduce((total, meter) => total + meter.readings[0].value, 0);
};

/**
 *
 * @param {array} meters - use selectMeters()
 * @param {string} timestamp  - any timestamp in the day
 */
export const selectElectricityAvgConsumption = (meters) => {
  if (!meters.length) return 0;

  const avgPerMeter = selectElectricityAvgConsumptionPerMeter(meters);

  return avgPerMeter.reduce((total, meter) => total + meter.readings[0].value, 0);
};
