export const FETCH_ELECTRICITY = 'FETCH_ELECTRICITY';
export const GOT_ELECTRICITY = 'GOT_ELECTRICITY';
export const APPEND_ELECTRICITY = 'APPEND_ELECTRICITY';
export const SET_ELECTRICITY = 'SET_ELECTRICITY';

export const fetchElectricity = () => ({
  type: FETCH_ELECTRICITY,
});

export const gotElectricity = (data) => ({
  type: GOT_ELECTRICITY,
  payload: data,
});

export const appendElectricity = (data) => ({
  type: APPEND_ELECTRICITY,
  payload: data,
});

export const setElectricity = (data) => ({
  type: SET_ELECTRICITY,
  payload: data,
});
