export const FETCH_GAS = 'FETCH_GAS';
export const GOT_GAS = 'GOT_GAS';
export const APPEND_GAS = 'APPEND_GAS';
export const SET_GAS = 'SET_GAS';

export const fetchGas = () => ({
  type: FETCH_GAS,
});

export const gotGas = (data) => ({
  type: GOT_GAS,
  payload: data,
});

export const appendGas = (data) => ({
  type: APPEND_GAS,
  payload: data,
});

export const setGas = (data) => ({
  type: SET_GAS,
  payload: data,
});
