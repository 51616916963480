import data1 from './fake1.json';
import data2 from './fake2.json';
import data3 from './fake3.json';
import data4 from './fake4.json';

import moment from 'moment';

import { apiRequest, fetchCyclists as fetchAction, gotCyclists as setAction } from 'redux/actions';
import { isInFuture } from 'utils';

const mockResponse = false;

export const getCyclistsData = (dispatch) => (from, to) => {
  if (mockResponse) {
    let data = [];

    if (from === '2020-08-30T00:00:00+02:00') {
      data = data1;
    } else if (from === '2020-09-06T00:00:00+02:00') {
      data = data2;
    } else if (from === '2020-09-13T00:00:00+02:00') {
      data = data3;
    } else if (from === '2020-09-20T00:00:00+02:00') {
      data = data4;
    }

    dispatch(fetchAction());
    setTimeout(() => {
      dispatch(setAction({ sensors: data }));
    }, 1000);

    return;
  }

  const params = {
    path: '/cyclists',
    filters: {
      startDate: moment(from).format(),
      endDate: moment(to).add(1, 'seconds').format(),
      simulation: true,
    },
  };

  if (!isInFuture(from)) {
    dispatch(apiRequest(params, fetchAction, setAction));
  }
};
