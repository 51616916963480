import React from 'react';
import { Link } from 'react-router-dom';

import { Back } from 'components/ui';

import styles from './BackToHomeLink.module.scss';

const BackToHomeLink = (props) => (
  <Link to="/" className={styles.back}>
    <Back /> <span className={styles.label}>Nazaj na pregled</span>
  </Link>
);

export default BackToHomeLink;
