import React from 'react';
import PropTypes from 'prop-types';

import { formatValue } from 'utils';
import * as units from 'utils/units';

import styles from './Circle.module.scss';

const Circle = (props) => {
  const getWidthAtDefaultZoom = (width, defaultZoom = 15) => width / Math.pow(2, defaultZoom - 1);
  const scale = Math.pow(2, props.zoom - 1);

  const size = 100 + Math.pow(props.value, 2.17);

  let r = Number.parseInt(100 + Math.pow(props.value, 2) / 3);
  let opacity = 0.2 + Math.pow(props.value, 0.7) / 10;

  opacity = Number.parseFloat(opacity).toPrecision(2);

  if (opacity > 1) opacity = 1;
  if (r > 130) r = 130;

  const [value, unit] = formatValue(props.value, units.electricity);

  return (
    <div className={styles.container} style={{ width: getWidthAtDefaultZoom(size) * scale }}>
      <svg viewBox="0 0 260 260" xmlns="http://www.w3.org/2000/svg">
        <circle opacity={opacity} cx="130" cy="130" r={r} fill="#25527C" />
        <path
          d="M40 130C40 80.2866 80.2864 40 130 40C179.713 40 220 80.2866 220 130C220 179.713 179.713 220 130 220C80.2864 220 40 179.713 40 130Z"
          fill="#F7F9FB"
        />
        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" className={styles.value}>
          {value}
        </text>
        <text x="50%" y="50%" dy="30" dominantBaseline="middle" textAnchor="middle" className={styles.label}>
          {unit}
        </text>
      </svg>
    </div>
  );
};

Circle.propTypes = {
  zoom: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default Circle;
