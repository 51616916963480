import data from './fake.json';
import moment from 'moment';

import { apiRequest, fetchTraffic as fetchAction, gotTraffic as setAction } from 'redux/actions';
import { isInFuture } from 'utils';

const mockResponse = false;
const simulation = undefined;

export const getTrafficData = (dispatch) => (from, to) => {
  if (mockResponse) {
    dispatch(fetchAction());
    setTimeout(() => {
      dispatch(setAction({ meters: data }));
    }, 1000);

    return;
  }

  const params = {
    path: '/traffic',
    filters: {
      startDate: moment(from).format(),
      endDate: moment(to).add(1, 'seconds').format(),
      simulation,
    },
  };

  if (!isInFuture(from)) {
    dispatch(apiRequest(params, fetchAction, setAction));
  }
};
