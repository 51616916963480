import moment from 'moment';

import { selectBetween } from '.';

const alterTimestamp = (reading, timestamp, incrementByDay = false, step = 900) => {
  const knownTime = moment(reading.timestamp);

  let secondsFromStartOfDay = knownTime.hour() * 3600 + knownTime.minute() * 60 + knownTime.second();

  secondsFromStartOfDay += incrementByDay && secondsFromStartOfDay === 0 ? 86400 : 0;

  const futureTimestamp = moment(timestamp).add(secondsFromStartOfDay, 'seconds').unix();
  const exact = futureTimestamp - (futureTimestamp % step);
  const futureTime = moment(exact * 1000);

  return {
    ...reading,
    timestamp: futureTime.format(),
  };
};

export const alterReading = (reading) => {
  const f = {};

  for (const key in reading) {
    if (reading.hasOwnProperty(key)) {
      const val = reading[key];

      if (typeof val === 'number') {
        // const factor = 1 + Math.floor(Math.random() * 2) / 10 - 0.1;
        const factor = 1;

        f[key] = reading[key] * factor;
      } else {
        f[key] = reading[key];
      }
    }
  }

  return f;
};

/**
 *
 * @param {array} meters - of objects format {[knownKey]: float|num, [string]: any}. Must include full day
 * @param {array} allMetersData - source data with same transformations as above
 * @param {string} timestamp - Future day start
 */
export const predictFutureData = (meters, allMetersData, timestamp, interval = 900) => {
  if (!meters.length) return meters;

  const start = moment(timestamp).startOf('day');
  const readingsInADay = 86400 / interval + 1;
  const minReadingsToBeValidForPrediction = readingsInADay * 0.8; // imperfect data for all electricity

  const oneWeekBack = {
    start: start.clone().subtract(1, 'weeks'),
    end: start.clone().add(1, 'days').subtract(1, 'weeks'),
  };
  const yesterday = {
    start: start.clone().subtract(1, 'days'),
    end: start.clone(),
  };
  const lastReading = allMetersData[0].readings.reduce((t, r) => {
    const a = moment(t).valueOf();
    const b = moment(r.timestamp).valueOf();

    return a > b ? a : b;
  });

  // Select data for previous week
  let sourceData = selectBetween(allMetersData, oneWeekBack.start, oneWeekBack.end);

  // select matching day in last full week
  if (sourceData[0].readings.length < minReadingsToBeValidForPrediction) {
    const startOfDayInLastKnownWeek = moment(lastReading)
      .startOf('week')
      .subtract(1, 'week')
      .isoWeekday(start.isoWeekday());

    const lastKnownWeek = {
      start: startOfDayInLastKnownWeek.clone(),
      end: startOfDayInLastKnownWeek.clone().add(1, 'd'),
    };

    sourceData = selectBetween(allMetersData, lastKnownWeek.start, lastKnownWeek.end);
  }

  // Select data for previous day
  if (sourceData[0].readings.length < minReadingsToBeValidForPrediction) {
    sourceData = selectBetween(allMetersData, yesterday.start, yesterday.end);
  }

  // select last known full day
  if (sourceData[0].readings.length < minReadingsToBeValidForPrediction) {
    const lastKnownDay = {
      start: moment(lastReading).startOf('day').subtract(1, 'days').clone(),
      end: moment(lastReading).startOf('day').clone(),
    };

    sourceData = selectBetween(allMetersData, lastKnownDay.start, lastKnownDay.end);
  }

  const timeForComparison = (ts) => ts.substring(11, 19);

  return sourceData.map((meter, mi) => ({
    ...meter,
    readings: meter.readings.map((reading, ri) => {
      const fn = (red) => timeForComparison(red.timestamp) === timeForComparison(reading.timestamp);
      const knownData = meters[mi].readings.find(fn);

      if (knownData) return alterTimestamp(knownData, start, ri > 0, interval);

      return alterTimestamp(alterReading(reading), start, ri > 0, interval);
    }),
  }));
};
