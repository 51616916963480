import { FETCH_ENVIRONMENT, SET_ENVIRONMENT, fetchLights, setLights, setMeters } from '../../actions';

// Breaks environment into separate stores
export const environmentMdl = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === FETCH_ENVIRONMENT) {
    dispatch(fetchLights());
  }

  const makeMeterId = (location) => `meter-at-${location.latitude}-${location.longitude}`;

  if (action.type === SET_ENVIRONMENT) {
    /*
     * Transform data to common format
     */
    const normalizedMeters = action.payload.sensors.map((sensor) => ({
      ...sensor,
      meter: makeMeterId(sensor.location),
    }));

    /*
     * Some meters (currently just one) include more data than others
     * we use this to populate air and traffic stores
     */
    const lightMeters = normalizedMeters.filter((meter) => !meter.readings[0].averageTrafficSpeed);

    const lightsData = normalizedMeters.map((meter) => ({
      ...meter,
      readings: meter.readings.map((reading) => ({
        timestamp: reading.timestamp,
        activePower: reading.activePower,
        dimmingLevel: reading.dimmingLevel,
      })),
    }));

    const metersDataLight = lightMeters.map((meter) => ({
      meter: meter.meter,
      location: meter.location,
      type: 'light',
    }));

    dispatch(setLights(lightsData));
    dispatch(setMeters(metersDataLight));
  }
};
