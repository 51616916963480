/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { selectWeekData, selectLineData, selectDayAverage, selectCurrentData } from 'redux/selectors';

import { getRodentsData } from 'redux/services';

import { formatNumber, dataRange, formatSelectedInterval, pluralize } from 'utils';
import { combineLines } from 'components/charts/LineChart';
import {
  Page,
  Sidebar,
  Main,
  Map,
  BackToHomeLink,
  LineChart,
  BarChart,
  InfoBubble,
  PinRodent as Pin,
} from 'components';

import styles from './Rodents.module.scss';

const label = 'Število odprtij jaška';
const label2 = 'Število glodavcev';
const key = 'timesOpened';
const key2 = 'numberOfRodents';

const lineSettings = [
  { key: 'day', label, color: '#FFD400' },
  { key: 'dayf', label, color: '#FFF2B3' },
];
const line2Settings = [
  { key: 'day', label: label2, color: '#FFD400' },
  { key: 'dayf', label: label2, color: '#FFF2B3' },
];
const barSettings = [
  { key: 'future', stackId: 'day' },
  { key: 'value', stackId: 'day' },
];
const bar2Settings = [
  { key: 'future', stackId: 'day' },
  { key: 'value', stackId: 'day' },
];

const Rodents = (props) => {
  const { lineToday, lineFuture, weekBars, selectedTime, lineToday2, lineFuture2, weekBars2, dataInterval } = props;
  const timestamp = moment(selectedTime).valueOf();

  const [lineGraphData, setLineGraphData] = useState([]);
  const [line2GraphData, setLine2GraphData] = useState([]);
  const [barGraphData, setBarGraphData] = useState([]);
  const [bar2GraphData, setBar2GraphData] = useState([]);

  useEffect(() => {
    props.getApiData(dataInterval.start, dataInterval.end);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInterval.start, dataInterval.end]);

  useEffect(() => {
    setLineGraphData(combineLines(lineToday, lineFuture));
    setBarGraphData(weekBars);

    setLine2GraphData(combineLines(lineToday2, lineFuture2));
    setBar2GraphData(weekBars2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineToday, lineFuture]);

  return (
    <Page>
      <Sidebar>
        <BackToHomeLink />
        <h1>Glodavci</h1>
        <p>
          Prikaz <b>testnih</b> podatkov v merilnem intervalu 1h.
        </p>
        <h2>Predvideno število odprtij jaška</h2>
        <span className="text-large">
          {formatNumber(props.sum)} {pluralize(props.sum, ['odprtje', 'odprtji', 'odprtja', 'odprtja', 'odprtij'])}
        </span>
        <h2>Dnevni trend odpriranj</h2>
        <div className={styles.lineGraph}>
          <LineChart
            data={lineGraphData}
            lines={lineSettings}
            currentTimestamp={timestamp}
            loading={props.inProgress}
            reference={{ point: props.avg, label: 'Povprečno število' }}
          />
        </div>
        <h2>tedensko število odprtij</h2>

        <BarChart data={barGraphData} bars={barSettings} currentTimestamp={timestamp} loading={props.inProgress} />

        <h2>Predvideno število glodavcev</h2>
        <span className="text-large">
          {formatNumber(props.sum2)}{' '}
          {pluralize(props.sum2, ['glodavec', 'glodavca', 'glodavci', 'glodavci', 'glodavcev'])}
        </span>
        <h2>Dnevni trend</h2>

        <div className={styles.lineGraph}>
          <LineChart
            data={line2GraphData}
            lines={line2Settings}
            currentTimestamp={timestamp}
            loading={props.inProgress}
            reference={{ point: props.avg2, label: 'Povprečno število' }}
          />
        </div>
        <h2>tedensko število glodavcev</h2>

        <BarChart data={bar2GraphData} bars={bar2Settings} currentTimestamp={timestamp} loading={props.inProgress} />
      </Sidebar>
      <Main>
        <Map step={3600}>
          {props.selectedData.map((meter) => {
            const a = meter.readings.length ? meter.readings[0][key] : 0;
            const b = meter.readings.length ? meter.readings[0][key2] : 0;

            const cName = ['readings', props.isSelectedPrediction ? 'predictionData' : 'realData'];

            return (
              <InfoBubble
                key={meter.meter}
                lng={meter.location.longitude}
                lat={meter.location.latitude}
                zoom={1}
                hover
                icon={<Pin />}
              >
                <h3>
                  Glodavci <span>{formatSelectedInterval(timestamp)}</span>
                </h3>
                <div className={cName.join(' ')}>
                  <span className="label">{label}:</span>
                  <span className="value">{a}</span>
                  <span className="label">{label2}:</span>
                  <span className="value">{b}</span>
                </div>
              </InfoBubble>
            );
          })}
        </Map>
      </Main>
    </Page>
  );
};

const mapStateToProps = (state) => {
  const { hour, dayIndex } = state.ui.timeline;

  const [isSelectedPrediction, redoutHourData] = selectCurrentData(state, 'rodents', undefined, undefined, 3600);
  const weekBars = selectWeekData(state, 'rodents', key);
  const [lineToday, lineFuture] = selectLineData(state, 'rodents', key, 'day');

  const weekBars2 = selectWeekData(state, 'rodents', key2);
  const [lineToday2, lineFuture2] = selectLineData(state, 'rodents', key2, 'day');

  return {
    currentTime: state.ui.currentTime,
    selectedDate: state.ui.timeline.selected,
    timeline: state.ui.timeline,
    inProgress: state.api.pending.length > 0,
    selectedTime: hour.start,
    isSelectedPrediction,
    dataInterval: dataRange(state.ui.timeline, state.ui.timeline.selected),

    avg: selectDayAverage(state, 'rodents', key),
    sum: weekBars[dayIndex].value + weekBars[dayIndex].future,
    lineToday,
    lineFuture,
    weekBars,

    avg2: selectDayAverage(state, 'rodents', key2),
    sum2: weekBars2[dayIndex].value + weekBars2[dayIndex].future,
    lineToday2,
    lineFuture2,
    weekBars2,

    selectedData: redoutHourData,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getApiData: getRodentsData(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Rodents);
