/* eslint-disable max-lines-per-function, max-statements */
import React, { useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import {
  getElectricityData,
  getEnvironmentData,
  getEnvironment2Data,
  getCo2Data,
  getWaterData,
  getGasData,
  getTrafficData,
  getCyclistsData,
  getRodentsData,
  getPackagesData,
} from 'redux/services';
import {
  selectTodayLineData,
  selectTodayAvgLineData,
  selectTodayTotal,
  selectTodayAverage,
  selectTodayAverageAverage,
} from 'redux/selectors';

import { Page, Sidebar, Main, Map, Button, routerPaths, LineChart, Meter } from 'components';
import { dataRange, emptyData, printValue } from 'utils';
import * as units from 'utils/units';

import styles from './Home.module.scss';

const electricitySettings = [
  { key: 'electricity', label: 'Trenutna poraba', color: '#2B9974' },
  { key: 'electricityf', label: 'Trenutna poraba', color: '#B5D9D2' },
];
const airSettings = [
  { key: 'pm025', label: 'Koncentracija PM<sub>2,5</sub>', color: '#3B6590' },
  { key: 'pm025f', label: 'Koncentracija PM<sub>2,5</sub>', color: '#BACADA' },
];
const waterSettings = [
  { key: 'day', label: 'Trenutna poraba', color: '#8AB1DB' },
  { key: 'dayf', label: 'Trenutna poraba', color: '#D1E1F1' },
];
const trafficSettings = [
  { key: 'averageSpeed', label: 'Povprečna hitrost', color: '#FFD400' },
  { key: 'averageSpeedf', label: 'Povprečna hitrost', color: '#FFF2B3' },
];
const lightsSettings = [
  { key: 'power', label: 'Trenutna poraba', color: '#CA1817' },
  { key: 'powerf', label: 'Trenutna poraba', color: '#E8C8CC' },
];
const cyclingSettings = [
  { key: 'day', label: 'Število kolesarjev', color: '#E6B900' },
  { key: 'dayf', label: 'Število kolesarjev', color: '#EDE3AF' },
];
const rodentsSettings = [
  { key: 'day', label: 'Število odprtij jaška', color: '#778493' },
  { key: 'dayf', label: 'Število odprtij jaška', color: '#CCD3DB' },
];
const packageSettings = [
  { key: 'day', label: 'Število paketov', color: '#FB706F' },
  { key: 'dayf', label: 'Število paketov', color: '#F3CDD0' },
];
const gasSettings = [
  { key: 'day', label: 'Trenutna poraba', color: '#68C69B' },
  { key: 'dayf', label: 'Trenutna poraba', color: '#C7E7DD' },
];
const co2Settings = [
  { key: 'day', label: 'Trenutni izpust', color: '#2D3847' },
  { key: 'dayf', label: 'Trenutni izpust', color: '#778493' },
];

const Home = (props) => {
  const { initialDataRange, inProgress } = props;
  const valueOfCurrentTime = moment(props.currentTime).valueOf();

  useEffect(() => {
    props.getElectricityData(initialDataRange.start, initialDataRange.end);
    props.getEnvironmentData(initialDataRange.start, initialDataRange.end);
    props.getEnvironment2Data(initialDataRange.start, initialDataRange.end);
    props.getCo2Data(initialDataRange.start, initialDataRange.end);
    props.getWaterData(initialDataRange.start, initialDataRange.end);
    props.getGasData(initialDataRange.start, initialDataRange.end);
    props.getTrafficData(initialDataRange.start, initialDataRange.end);
    props.getCyclistsData(initialDataRange.start, initialDataRange.end);
    props.getRodentsData(initialDataRange.start, initialDataRange.end);
    props.getPackagesData(initialDataRange.start, initialDataRange.end);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className={styles.page}>
      <Sidebar>
        <div className={styles.box}>
          <h2 className="h1">Električna energija</h2>
          <h3 className="h2">Poraba danes</h3>
          <span className="text-large">{printValue(props.electricityTodayTotal, units.electricity)}</span>

          <LineChart
            data={props.electricityToday}
            lines={electricitySettings}
            currentTimestamp={valueOfCurrentTime}
            loading={inProgress}
            height={130}
            hideLegend
            hideAxis
            units={units.electricity}
            noData={emptyData(props.electricityToday, electricitySettings[0].key)}
          />

          <div className={styles.bottom}>
            <Button to={routerPaths.electricity.path} variant="green">
              Podrobneje
            </Button>
          </div>
        </div>

        <div className={styles.box}>
          <h2 className="h1">Zrak</h2>
          <h3 className="h2">
            Koncentracija PM<sub>2,5</sub>
          </h3>
          <span className="text-large">{printValue(props.airTodayAverage, units.air)}</span>

          <LineChart
            data={props.airToday}
            lines={airSettings}
            currentTimestamp={valueOfCurrentTime - (valueOfCurrentTime % 3600000)}
            loading={inProgress}
            height={130}
            hideLegend
            hideAxis
            units={units.air}
            noData={emptyData(props.airToday, airSettings[0].key)}
          />

          <div className={styles.bottom}>
            <Button to={routerPaths.air.path} variant="blue">
              Podrobneje
            </Button>
          </div>
        </div>

        <div className={styles.box}>
          <h2 className="h1">Voda</h2>
          <h3 className="h2">Poraba danes</h3>
          <span className="text-large">{printValue(props.waterSum, units.water)}</span>

          <LineChart
            data={props.waterToday}
            lines={waterSettings}
            currentTimestamp={valueOfCurrentTime - (valueOfCurrentTime % 3600000)}
            loading={inProgress}
            height={130}
            hideLegend
            hideAxis
            units={units.water}
          />

          <div className={styles.bottom}>
            <Button to={routerPaths.water.path} variant="blue2">
              Podrobneje
            </Button>
          </div>
        </div>

        <div className={styles.box}>
          <h2 className="h1">Promet</h2>
          <h3 className="h2">Povprečna hitrost</h3>
          <span className="text-large">{printValue(props.trafficTotalAverageSpeed, units.speed)}</span>

          <LineChart
            data={props.trafficAverageSpeedToday}
            lines={trafficSettings}
            currentTimestamp={valueOfCurrentTime - (valueOfCurrentTime % 3600000)}
            loading={inProgress}
            height={130}
            hideLegend
            hideAxis
            units={units.speed}
            noData={emptyData(props.trafficAverageSpeedToday, trafficSettings[0].key)}
          />

          <div className={styles.bottom}>
            <Button to={routerPaths.traffic.path} variant="yellow">
              Podrobneje
            </Button>
          </div>
        </div>

        <div className={styles.box}>
          <h2 className="h1">Osvetlitev</h2>
          <h3 className="h2">Poraba danes</h3>
          <span className="text-large">{printValue(props.lightsTodayPower, units.electricity)}</span>

          <LineChart
            data={props.lightsToday}
            lines={lightsSettings}
            currentTimestamp={valueOfCurrentTime - (valueOfCurrentTime % 3600000)}
            loading={inProgress}
            height={130}
            hideLegend
            hideAxis
            units={units.electricity}
            noData={emptyData(props.lightsToday, lightsSettings[0].key)}
          />

          <div className={styles.bottom}>
            <Button to={routerPaths.lights.path} variant="red">
              Podrobneje
            </Button>
          </div>
        </div>

        <div className={styles.box}>
          <h2 className="h1">Kolesarji</h2>
          <h3 className="h2">Število kolesarjev</h3>
          <span className="text-large">{props.cyclistsSum}</span>

          <LineChart
            data={props.cyclistsToday}
            lines={cyclingSettings}
            currentTimestamp={valueOfCurrentTime - (valueOfCurrentTime % 3600000)}
            loading={inProgress}
            height={130}
            hideLegend
            hideAxis
          />

          <div className={styles.bottom}>
            <Button to={routerPaths.cycling.path} variant="yellow2">
              Podrobneje
            </Button>
          </div>
        </div>

        <div className={styles.box}>
          <h2 className="h1">Glodavci</h2>
          <h3 className="h2">število odprtij jaška</h3>
          <span className="text-large">{props.rodentsSum}</span>

          <LineChart
            data={props.rodentsToday}
            lines={rodentsSettings}
            currentTimestamp={valueOfCurrentTime - (valueOfCurrentTime % 3600000)}
            loading={inProgress}
            height={130}
            hideLegend
            hideAxis
          />

          <div className={styles.bottom}>
            <Button to={routerPaths.rodent.path} variant="neutral">
              Podrobneje
            </Button>
          </div>
        </div>

        <div className={styles.box}>
          <h2 className="h1">Paketomat</h2>
          <h3 className="h2">Število novih pošiljk</h3>
          <span className="text-large">{props.packageSum}</span>

          <LineChart
            data={props.packageToday}
            lines={packageSettings}
            currentTimestamp={valueOfCurrentTime - (valueOfCurrentTime % 3600000)}
            loading={inProgress}
            height={130}
            hideLegend
            hideAxis
          />

          <div className={styles.bottom}>
            <Button to={routerPaths.package.path} variant="red2">
              Podrobneje
            </Button>
          </div>
        </div>

        <div className={styles.box}>
          <h2 className="h1">Plin</h2>
          <h3 className="h2">Poraba danes</h3>
          <span className="text-large">{printValue(props.gasSum, units.gas)}</span>

          <LineChart
            data={props.gasToday}
            lines={gasSettings}
            currentTimestamp={valueOfCurrentTime - (valueOfCurrentTime % 3600000)}
            loading={inProgress}
            height={130}
            hideLegend
            hideAxis
            units={units.gas}
          />

          <div className={styles.bottom}>
            <Button to={routerPaths.gas.path} variant="green2">
              Podrobneje
            </Button>
          </div>
        </div>

        <div className={styles.box}>
          <h2 className="h1">
            IZPUST CO<sub>2</sub>
          </h2>
          <h3 className="h2">Izpust danes</h3>
          <span className="text-large">{printValue(props.co2Sum, units.co2)}</span>

          <LineChart
            data={props.co2Today}
            lines={co2Settings}
            currentTimestamp={valueOfCurrentTime - (valueOfCurrentTime % 3600000)}
            loading={inProgress}
            height={130}
            hideLegend
            hideAxis
            units={units.co2}
          />

          <div className={styles.bottom}>
            <Button to={routerPaths.co2.path} variant="dark">
              Podrobneje
            </Button>
          </div>
        </div>
      </Sidebar>
      <Main>
        <Map withoutTimeline>
          {props.meters.map((meter) => {
            if (meter.meter === 'M1+M2') return null;

            return (
              <Meter key={meter.meter} lat={meter.location.latitude} lng={meter.location.longitude} type={meter.type} />
            );
          })}
        </Map>
      </Main>
    </Page>
  );
};

const mapStateToProps = (state) => {
  const { selected } = state.ui;
  const initialDataRange = dataRange(state.ui.timeline, selected);

  return {
    initialDataRange,
    inProgress: state.api.pending.length > 0,
    currentTime: state.ui.currentTime,
    today: state.ui.today,
    meters: state.meters,
    electricityToday: selectTodayLineData(state, 'electricity', 'value', 'electricity', 900),
    electricityTodayTotal: selectTodayTotal(state, 'electricity', 'value'),

    trafficAverageSpeedToday: selectTodayLineData(state, 'traffic', 'carAverageSpeed', 'averageSpeed'),
    trafficTotalAverageSpeed: selectTodayAverage(state, 'traffic', 'carAverageSpeed'),

    airToday: selectTodayAvgLineData(state, 'air', 'concentrationOfPM025', 'pm025'),
    airTodayAverage: selectTodayAverageAverage(state, 'air', 'concentrationOfPM025'),

    lightsToday: selectTodayLineData(state, 'lights', 'activePower', 'power'),
    lightsTodayPower: selectTodayTotal(state, 'lights', 'activePower'),

    cyclistsToday: selectTodayLineData(state, 'cyclists', 'numberOfCyclists', 'day'),
    cyclistsSum: selectTodayTotal(state, 'cyclists', 'numberOfCyclists'),

    rodentsToday: selectTodayLineData(state, 'rodents', 'timesOpened', 'day'),
    rodentsSum: selectTodayTotal(state, 'rodents', 'timesOpened'),

    packageToday: selectTodayLineData(state, 'package', 'numberOfPackages', 'day'),
    packageSum: selectTodayTotal(state, 'package', 'numberOfPackages'),

    gasToday: selectTodayLineData(state, 'gas', 'value', 'day'),
    gasSum: selectTodayTotal(state, 'gas', 'value'),

    waterToday: selectTodayLineData(state, 'water', 'value', 'day'),
    waterSum: selectTodayTotal(state, 'water', 'value'),

    co2Today: selectTodayLineData(state, 'co2', 'value', 'day'),
    co2Sum: selectTodayTotal(state, 'co2', 'value'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getElectricityData: getElectricityData(dispatch),
  getEnvironmentData: getEnvironmentData(dispatch),
  getEnvironment2Data: getEnvironment2Data(dispatch),
  getCo2Data: getCo2Data(dispatch),
  getWaterData: getWaterData(dispatch),
  getGasData: getGasData(dispatch),
  getCyclistsData: getCyclistsData(dispatch),
  getRodentsData: getRodentsData(dispatch),
  getPackagesData: getPackagesData(dispatch),
  getTrafficData: getTrafficData(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
