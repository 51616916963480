import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { selectDayMax, selectWeekData, selectLineData, selectDayAverage, selectCurrentData } from 'redux/selectors';

import { getTrafficData } from 'redux/services';

import { dataRange, emptyData, emptyWeekData, printValue, formatSelectedInterval } from 'utils';
import * as units from 'utils/units';
import { combineLines } from 'components/charts/LineChart';
import { Page, Sidebar, Main, Map, BackToHomeLink, LineChart, BarChart, InfoBubble, PinSpeed } from 'components';

import styles from './Traffic.module.scss';

const avgSpeedLineSettings = [
  { key: 'car', label: 'Avtomobili', color: '#FFD400' },
  { key: 'van', label: 'Dostavna vozila', color: '#2B9974' },
  { key: 'truck', label: 'Tovornjaki', color: '#6D96C1' },
  { key: 'carf', label: 'Avtomobili', color: '#FFF2B3' },
  { key: 'vanf', label: 'Dostavna vozila', color: '#B5D9D2' },
  { key: 'truckf', label: 'Tovornjaki', color: '#D3DFEC' },
];
const maxSpeedLineSettings = [
  { key: 'car', label: 'Avtomobili', color: '#FFD400' },
  { key: 'van', label: 'Dostavna vozila', color: '#2B9974' },
  { key: 'truck', label: 'Tovornjaki', color: '#6D96C1' },
  { key: 'carf', label: 'Avtomobili', color: '#FFF2B3' },
  { key: 'vanf', label: 'Dostavna vozila', color: '#B5D9D2' },
  { key: 'truckf', label: 'Tovornjaki', color: '#D3DFEC' },
];
const weekCarBarSettings = [
  { key: 'future', stackId: 'day', color: '#FFF2B3' },
  { key: 'value', stackId: 'day', color: '#FFD400' },
];
const weekVanBarSettings = [
  { key: 'future', stackId: 'day', color: '#B5D9D2' },
  { key: 'value', stackId: 'day', color: '#2B9974' },
];
const weekTruckBarSettings = [
  { key: 'future', stackId: 'day', color: '#D3DFEC' },
  { key: 'value', stackId: 'day', color: '#6D96C1' },
];

const Traffic = (props) => {
  const {
    avgSpeedCar,
    avgSpeedTruck,
    avgSpeedVan,
    avgSpeedCarF,
    avgSpeedTruckF,
    avgSpeedVanF,

    maxSpeedCar,
    maxSpeedCarF,
    maxSpeedTruck,
    maxSpeedTruckF,
    maxSpeedVan,
    maxSpeedVanF,
    selectedTime,
    dataInterval,
    weekBarCars,
    weekBarVans,
    weekBarTrucks,
  } = props;

  const timestamp = moment(selectedTime).valueOf();

  const [avgSpeedData, setAvgSpeedData] = useState([]);
  const [maxSpeedData, setMaxSpeedData] = useState([]);
  const [weekCarData, setWeekCarData] = useState([]);
  const [weekVanData, setWeekVanData] = useState([]);
  const [weekTruckData, setWeekTruckData] = useState([]);

  useEffect(() => {
    props.getTrafficData(dataInterval.start, dataInterval.end);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInterval.start, dataInterval.end]);

  useEffect(() => {
    setAvgSpeedData(combineLines(avgSpeedCar, avgSpeedTruck, avgSpeedVan, avgSpeedCarF, avgSpeedTruckF, avgSpeedVanF));
    setMaxSpeedData(combineLines(maxSpeedCar, maxSpeedCarF, maxSpeedTruck, maxSpeedTruckF, maxSpeedVan, maxSpeedVanF));
    setWeekCarData(weekBarCars);
    setWeekVanData(weekBarVans);
    setWeekTruckData(weekBarTrucks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    avgSpeedCar,
    avgSpeedTruck,
    avgSpeedVan,
    avgSpeedCarF,
    avgSpeedTruckF,
    avgSpeedVanF,
    maxSpeedCar,
    maxSpeedCarF,
    maxSpeedTruck,
    maxSpeedTruckF,
    maxSpeedVan,
    maxSpeedVanF,
    weekBarCars,
    weekBarVans,
    weekBarTrucks,
  ]);

  return (
    <Page>
      <Sidebar>
        <BackToHomeLink />
        <h1>Promet</h1>
        <p>Izmera zračnih vrednosti v merilnem intervalu 1h.</p>
        <h2>Predvidena dnevna povprečna hitrost</h2>
        <span className="text-large">{printValue(props.avgSpeed, units.speed)}</span>
        <h2>dnevni trend povprečne hitrosti</h2>
        <div className={styles.removeLastLegend3}>
          <LineChart
            data={avgSpeedData}
            lines={avgSpeedLineSettings}
            currentTimestamp={timestamp}
            loading={props.inProgress}
            reference={{ point: props.avgSpeed, label: 'Povprečna hitrost' }}
            units={units.speed}
            noData={emptyData(avgSpeedData, avgSpeedLineSettings[0].key, props.selectedDate)}
          />
        </div>

        <h2>Predvidena dnevna maksimalna hitrost</h2>
        <span className="text-large">{printValue(props.maxSpeed, units.speed)}</span>
        <h2>Dnevni trend maksimalne hitrosti</h2>
        <div className={styles.removeLastLegend3}>
          <LineChart
            data={maxSpeedData}
            lines={maxSpeedLineSettings}
            currentTimestamp={timestamp}
            loading={props.inProgress}
            reference={{ point: 50, label: '50 km/h' }}
            units={units.speed}
            noData={emptyData(maxSpeedData, maxSpeedLineSettings[0].key, props.selectedDate)}
          />
        </div>
        <h2>Predvideno dnevno število vozil</h2>
        <span className="text-large">{props.carCount} vozil</span>

        <h2>Tedensko število avtomobilov</h2>
        <div className={styles.barHoverYellow}>
          <BarChart
            data={weekCarData}
            bars={weekCarBarSettings}
            currentTimestamp={timestamp}
            loading={props.inProgress}
            noData={emptyWeekData(weekCarData, 'value', props.selectedDate)}
          />
        </div>

        <h2>Tedensko število dostavnih vozil</h2>
        <div className={styles.barHoverGreen}>
          <BarChart
            data={weekVanData}
            bars={weekVanBarSettings}
            currentTimestamp={timestamp}
            loading={props.inProgress}
            noData={emptyWeekData(weekVanData, 'value', props.selectedDate)}
          />
        </div>

        <h2>Tedensko število tovornjakov</h2>

        <div className={styles.barHoverBlue}>
          <BarChart
            data={weekTruckData}
            bars={weekTruckBarSettings}
            currentTimestamp={timestamp}
            loading={props.inProgress}
            noData={emptyWeekData(weekTruckData, 'value', props.selectedDate)}
          />
        </div>
      </Sidebar>
      <Main>
        <Map step={3600}>
          {props.selectedData.map((meter) => {
            const carMax = meter.readings.length ? meter.readings[0].carMaximumSpeed : '/';
            const carAvg = meter.readings.length ? meter.readings[0].carAverageSpeed : '/';
            const carNumber = meter.readings.length ? meter.readings[0].numberOfCars : '/';
            const truckMax = meter.readings.length ? meter.readings[0].truckMaximumSpeed : '/';
            const truckAvg = meter.readings.length ? meter.readings[0].truckAverageSpeed : '/';
            const truckNumber = meter.readings.length ? meter.readings[0].numberOfTrucks : '/';
            const vanMax = meter.readings.length ? meter.readings[0].vanMaximumSpeed : '/';
            const vanAvg = meter.readings.length ? meter.readings[0].vanAverageSpeed : '/';
            const vanNumber = meter.readings.length ? meter.readings[0].numberOfVans : '/';
            const cName = ['readings', props.isSelectedPrediction ? 'predictionData' : 'realData'];

            return (
              <InfoBubble
                key={meter.meter}
                lng={meter.location.longitude}
                lat={meter.location.latitude}
                zoom={1}
                icon={<PinSpeed />}
              >
                <h3>
                  avtomobili <span>{formatSelectedInterval(timestamp)}</span>
                </h3>
                <div className={cName.join(' ')}>
                  <span className="label">Število</span>
                  <span className="value">{carNumber || '/'}</span>

                  <span className="label">Maksimalna hitrost</span>
                  <span className="value">{printValue(carMax, units.speed)}</span>

                  <span className="label">Povprečna hitrost</span>
                  <span className="value">{printValue(carAvg, units.speed)}</span>
                </div>
                <br />
                <h3>
                  dostavna vozila <span>{formatSelectedInterval(timestamp)}</span>
                </h3>
                <div className={cName.join(' ')}>
                  <span className="label">Število:</span>
                  <span className="value">{vanNumber || '/'}</span>

                  <span className="label">Maksimalna hitrost:</span>
                  <span className="value">{printValue(vanMax, units.speed)}</span>

                  <span className="label">Povprečna hitrost:</span>
                  <span className="value">{printValue(vanAvg, units.speed)}</span>
                </div>

                <br />
                <h3>
                  tovornjaki <span>{formatSelectedInterval(timestamp)}</span>
                </h3>
                <div className={cName.join(' ')}>
                  <span className="label">Število:</span>
                  <span className="value">{truckNumber || '/'}</span>

                  <span className="label">Maksimalna hitrost:</span>
                  <span className="value">{printValue(truckMax, units.speed)}</span>

                  <span className="label">Povprečna hitrost:</span>
                  <span className="value">{printValue(truckAvg, units.speed)}</span>
                </div>
              </InfoBubble>
            );
          })}
        </Map>
      </Main>
    </Page>
  );
};

const mapStateToProps = (state) => {
  const { selected, dayIndex, hour } = state.ui.timeline;
  const dataInterval = dataRange(state.ui.timeline, selected);

  const [isSelectedPrediction, redoutHourData] = selectCurrentData(state, 'traffic', undefined, undefined, 3600);
  const weekBarCars = selectWeekData(state, 'traffic', 'numberOfCars');
  const weekBarVans = selectWeekData(state, 'traffic', 'numberOfVans');
  const weekBarTrucks = selectWeekData(state, 'traffic', 'numberOfTrucks');

  const [avgSpeedCar, avgSpeedCarF] = selectLineData(state, 'traffic', 'carAverageSpeed', 'car');
  const [avgSpeedTruck, avgSpeedTruckF] = selectLineData(state, 'traffic', 'truckAverageSpeed', 'truck');
  const [avgSpeedVan, avgSpeedVanF] = selectLineData(state, 'traffic', 'vanAverageSpeed', 'van');

  const [maxSpeedCar, maxSpeedCarF] = selectLineData(state, 'traffic', 'carMaximumSpeed', 'car');
  const [maxSpeedTruck, maxSpeedTruckF] = selectLineData(state, 'traffic', 'truckMaximumSpeed', 'truck');
  const [maxSpeedVan, maxSpeedVanF] = selectLineData(state, 'traffic', 'vanMaximumSpeed', 'van');

  return {
    currentTime: state.ui.currentTime,
    selectedDate: state.ui.timeline.selected,
    timeline: state.ui.timeline,
    inProgress: state.api.pending.length > 0,
    dataInterval,

    avgSpeedCar,
    avgSpeedTruck,
    avgSpeedVan,
    avgSpeedCarF,
    avgSpeedTruckF,
    avgSpeedVanF,

    maxSpeedCar,
    maxSpeedCarF,
    maxSpeedTruck,
    maxSpeedTruckF,
    maxSpeedVan,
    maxSpeedVanF,

    avgSpeed:
      (selectDayAverage(state, 'traffic', 'carAverageSpeed') +
        selectDayAverage(state, 'traffic', 'truckAverageSpeed') +
        selectDayAverage(state, 'traffic', 'vanAverageSpeed')) /
      3,
    maxSpeed: Math.max(
      selectDayMax(state, 'traffic', 'carMaximumSpeed'),
      selectDayMax(state, 'traffic', 'truckMaximumSpeed'),
      selectDayMax(state, 'traffic', 'vanMaximumSpeed'),
    ),

    carCount:
      weekBarCars[dayIndex].value +
      weekBarCars[dayIndex].future +
      weekBarVans[dayIndex].value +
      weekBarVans[dayIndex].future +
      weekBarTrucks[dayIndex].value +
      weekBarTrucks[dayIndex].future,

    weekBarCars,
    weekBarVans,
    weekBarTrucks,

    selectedTime: hour.start,
    selectedData: redoutHourData,
    isSelectedPrediction,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTrafficData: getTrafficData(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Traffic);
