import { selectLightsTotalPerMeter, selectLightsAveragePerMeter } from '.';

/**
 *
 * @param {array} meters - use selectMeters()
 * @param {string} timestamp  - any timestamp in the day
 */
export const selectLightsTotal = (meters, key) => {
  if (!meters.length) return 0;

  const totalPerMeter = selectLightsTotalPerMeter(meters, key);

  return totalPerMeter.reduce((total, meter) => total + meter.readings[0][key], 0);
};

/**
 *
 * @param {array} meters - use selectMeters()
 * @param {string} key  - object key
 */
export const selectLightsAverage = (meters, key) => {
  if (!meters.length) return 0;

  const averageByMeter = selectLightsAveragePerMeter(meters, key);

  return averageByMeter.reduce((avg, meter) => avg + meter.readings[0][key], 0) / averageByMeter.length;
};
