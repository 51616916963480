import moment from 'moment';

import { apiRequest, fetchElectricity as fetchAction, gotElectricity as setAction } from 'redux/actions';
import { isInFuture } from 'utils';

export const getElectricityData = (dispatch) => (from, to) => {
  const params = {
    path: '/energy/electricity',
    filters: {
      startDate: moment(from).format(),
      endDate: moment(to).add(1, 'seconds').format(),
      simulation: true,
    },
  };

  if (!isInFuture(from)) {
    dispatch(apiRequest(params, fetchAction, setAction));
  }
};
