import { SET_METERS } from '../../actions';

export const initialState = [];

export const metersReducer = (state = initialState, action = { type: undefined, payload: [] }) => {
  switch (action.type) {
    case SET_METERS:
      return state.concat(action.payload).reduce((all, row) => {
        if (!all.some((meter) => row.meter === meter.meter)) {
          all.push(row);
        }

        return all;
      }, []);

    default:
      return state;
  }
};
