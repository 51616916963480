import { addNewMetersToState, returnLoadingState, replaceMeters } from '../utils';
import { FETCH_CO2, APPEND_CO2, SET_CO2 } from '../../actions';

export const initialState = {
  loading: false,
  meters: [],
};

export const co2Reducer = (state = initialState, action = { type: undefined, payload: [] }) => {
  switch (action.type) {
    case FETCH_CO2:
      return returnLoadingState(state);

    case APPEND_CO2:
      return addNewMetersToState(state, action.payload);

    case SET_CO2:
      return replaceMeters(state, action.payload);

    default:
      return state;
  }
};
