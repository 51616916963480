import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { routerPaths } from 'components';
import {
  Electricity,
  Lights,
  Air,
  Traffic,
  Home,
  Error,
  Cycling,
  Rodents,
  Packages,
  Co2,
  Water,
  Gas,
  ErrorHandler,
} from 'containers';

import 'assets/scss/app.scss';

const App = () => {
  useEffect(() => {
    const minLoadingScreenTime = 5000;
    const loadingBox = document.getElementById('loadingScreen');

    let timeout = minLoadingScreenTime - (new Date().getTime() - window.accessTime);

    timeout = timeout < 0 ? 0 : timeout;

    if (loadingBox) {
      setTimeout(() => {
        loadingBox.classList.add('hide');

        setTimeout(() => {
          loadingBox.remove();
        }, 510);
      }, timeout);
    }
  }, []);

  return (
    <>
      <ErrorHandler />
      <Router>
        <Switch>
          <Route path={routerPaths.electricity.path}>
            <Electricity />
          </Route>
          <Route path={routerPaths.lights.path}>
            <Lights />
          </Route>
          <Route path={routerPaths.air.path}>
            <Air />
          </Route>
          <Route path={routerPaths.traffic.path}>
            <Traffic />
          </Route>

          <Route path={routerPaths.cycling.path}>
            <Cycling />
          </Route>
          <Route path={routerPaths.rodent.path}>
            <Rodents />
          </Route>
          <Route path={routerPaths.package.path}>
            <Packages />
          </Route>
          <Route path={routerPaths.co2.path}>
            <Co2 />
          </Route>
          <Route path={routerPaths.water.path}>
            <Water />
          </Route>
          <Route path={routerPaths.gas.path}>
            <Gas />
          </Route>

          <Route exact path={routerPaths.home.path}>
            <Home />
          </Route>
          <Route path="*">
            <Error />
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default App;
