/* eslint-disable newline-per-chained-call, function-paren-newline */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { getElectricityData } from 'redux/services';

import {
  selectMeters,
  lastTimestamp,
  predictFutureData,
  selectByDate as byDate,
  selectBetween as between,
  selectGraphData as graphData,
  selectElectricitySumByTime as sumByTime,
  selectElectricityTotalConsumption as totalConsumption,
  selectElectricityAvgConsumption as avgConsumption,
} from 'redux/selectors';
import { Page, Sidebar, Main, Map, LineChart, BarChart, PieChart, Circle, BackToHomeLink } from 'components';
import { lineData, combineLines } from 'components/charts/LineChart';
import { dataRange, selectedData, emptyData, emptyWeekData, printValue } from 'utils';
import * as units from 'utils/units';

import styles from './Electricity.module.scss';

const trendLineSettings = [
  { key: 'day', label: 'Izbrani dan' },
  { key: 'prev', label: 'Dan pred tem' },
  { key: 'dayf', label: 'Izbrani dan', color: '#FFF2B3' },
  { key: 'prevf', label: 'Dan pred tem', color: '#D3DFEC' },
];
const weekSumBarSettings = [
  { key: 'future', stackId: 'day' },
  { key: 'value', stackId: 'day' },
];

const Electricity = (props) => {
  const {
    selectedTime,
    dataInterval,
    dayLine,
    dayFLine,
    prevDayLine,
    prevDayFLine,
    weekBars,
    hightTariff,
    lowTariff,
  } = props;

  const timestamp = moment(selectedTime).valueOf();
  const [trendData, setTrendData] = useState([]);
  const [weekSumData, setWeekSumData] = useState([]);
  const [dayAverageData, setDayAverageData] = useState([]);

  useEffect(() => {
    props.getElectricityData(dataInterval.start, dataInterval.end);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInterval.start, dataInterval.end]);

  useEffect(() => {
    setTrendData(combineLines(dayLine, prevDayLine, dayFLine, prevDayFLine));
    setWeekSumData(weekBars);
    setDayAverageData([
      { name: 'Velika tarifa', value: hightTariff },
      { name: 'Mala tarifa', value: lowTariff },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dayLine, prevDayLine, prevDayFLine, dayFLine, weekBars, hightTariff, lowTariff]);

  return (
    <Page className={styles.page}>
      <Sidebar>
        <BackToHomeLink />
        <h1>Električna energija</h1>
        <p>Izmera porabe električne energije v merilnem intervalu 15 minut</p>
        <h2>Predvidena dnevna poraba električne energije</h2>
        <span className="text-large">{printValue(props.selectedTotalConsumption, units.electricity)}</span>
        <div className={styles.removeLastLegend}>
          <h2>Dnevni trend porabe električne energije</h2>
          <LineChart
            data={trendData}
            lines={trendLineSettings}
            currentTimestamp={timestamp}
            loading={props.inProgress}
            reference={{ point: props.selectedAvgConsumption, label: 'Povprečna poraba' }}
            units={units.electricity}
            noData={emptyData(trendData, trendLineSettings[0].key, props.selectedDate)}
          />
        </div>
        <h2>Tedenska poraba električne energije</h2>
        <BarChart
          data={weekSumData}
          bars={weekSumBarSettings}
          currentTimestamp={timestamp}
          loading={props.inProgress}
          units={units.electricity}
          noData={emptyWeekData(weekSumData, 'value', props.selectedDate)}
        />
        <h2>tedenska poraba MT / VT</h2>
        <PieChart
          data={dayAverageData}
          loading={props.inProgress}
          units={units.electricity}
          noData={hightTariff === 0 && lowTariff === 0}
        />
      </Sidebar>
      <Main>
        <Map>
          {props.selectedData.map((meter) => {
            const value = meter.readings.length ? meter.readings[0].value : 0;

            return (
              <Circle
                key={meter.meter}
                lng={meter.location.longitude}
                lat={meter.location.latitude}
                zoom={1}
                value={value}
              />
            );
          })}
        </Map>
      </Main>
    </Page>
  );
};

const calcHightAndLowTariff = (weekData, weekBars, times) => {
  const hightTariff = [
    totalConsumption(
      between(weekData[0], moment(times.monday.start).hour(6).format(), moment(times.monday.start).hour(22).format()),
    ),
    totalConsumption(
      between(weekData[1], moment(times.tuesday.start).hour(6).format(), moment(times.tuesday.start).hour(22).format()),
    ),
    totalConsumption(
      between(
        weekData[2],
        moment(times.wednesday.start).hour(6).format(),
        moment(times.wednesday.start).hour(22).format(),
      ),
    ),
    totalConsumption(
      between(
        weekData[3],
        moment(times.thursday.start).hour(6).format(),
        moment(times.thursday.start).hour(22).format(),
      ),
    ),
    totalConsumption(
      between(weekData[4], moment(times.friday.start).hour(6).format(), moment(times.friday.start).hour(22).format()),
    ),
    0,
    0,
  ];

  const lowTariff = [
    weekBars[0].value + weekBars[0].future - hightTariff[0],
    weekBars[1].value + weekBars[1].future - hightTariff[1],
    weekBars[2].value + weekBars[2].future - hightTariff[2],
    weekBars[3].value + weekBars[3].future - hightTariff[3],
    weekBars[4].value + weekBars[4].future - hightTariff[4],
    weekBars[5].value + weekBars[5].future - hightTariff[5],
    weekBars[6].value + weekBars[6].future - hightTariff[6],
  ];

  return {
    hightTariff: hightTariff.reduce((a, x) => a + x, 0),
    lowTariff: lowTariff.reduce((a, x) => a + x, 0),
  };
};

const mapStateToProps = (state) => {
  const {
    selected,
    quarterHour,
    day,
    previousDay,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
  } = state.ui.timeline;

  const meters = selectMeters(state.electricity);
  const lastKnownValue = lastTimestamp(meters);

  const dataInterval = dataRange(state.ui.timeline, selected);

  const sum = sumByTime(meters);

  const electricityDay = byDate(sum, day.start);
  const electricityDayPredictionData = predictFutureData(electricityDay, meters, day.start, 900);
  const electricityDayPrediction = between(electricityDayPredictionData, moment(lastKnownValue), moment(day.end));

  const electricityYesterday = byDate(sum, previousDay.start);
  const electricityYesterdayPredictionData = predictFutureData(electricityYesterday, meters, previousDay.start, 900);
  const electricityYesterdayPrediction = between(
    electricityYesterdayPredictionData,
    moment(lastKnownValue),
    moment(day.end),
  );

  const electricitySelectedDay = predictFutureData(byDate(meters, selected), meters, selected, 900);

  const [isSelectedPrediction, electricitySelectedData] = selectedData(
    byDate(meters, day.start),
    electricityDayPredictionData,
    quarterHour.start,
    900,
  );

  const selectedAvgConsumption = avgConsumption(electricityDayPredictionData);

  const weekData = [
    byDate(sum, monday.start, false),
    byDate(sum, tuesday.start, false),
    byDate(sum, wednesday.start, false),
    byDate(sum, thursday.start, false),
    byDate(sum, friday.start, false),
    byDate(sum, saturday.start, false),
    byDate(sum, sunday.start, false),
  ];
  const weekPrediction = [
    byDate(predictFutureData(weekData[0], sum, monday.start, 900), monday.start, false),
    byDate(predictFutureData(weekData[1], sum, tuesday.start, 900), tuesday.start, false),
    byDate(predictFutureData(weekData[2], sum, wednesday.start, 900), wednesday.start, false),
    byDate(predictFutureData(weekData[3], sum, thursday.start, 900), thursday.start, false),
    byDate(predictFutureData(weekData[4], sum, friday.start, 900), friday.start, false),
    byDate(predictFutureData(weekData[5], sum, saturday.start, 900), saturday.start, false),
    byDate(predictFutureData(weekData[6], sum, sunday.start, 900), sunday.start, false),
  ];

  const weekBars = [
    { day: 'pon', value: totalConsumption(weekData[0]), future: 0 },
    { day: 'tor', value: totalConsumption(weekData[1]), future: 0 },
    { day: 'sre', value: totalConsumption(weekData[2]), future: 0 },
    { day: 'čet', value: totalConsumption(weekData[3]), future: 0 },
    { day: 'pet', value: totalConsumption(weekData[4]), future: 0 },
    { day: 'sob', value: totalConsumption(weekData[5]), future: 0 },
    { day: 'ned', value: totalConsumption(weekData[6]), future: 0 },
  ];

  weekBars[0].future = totalConsumption(weekPrediction[0]) - weekBars[0].value;
  weekBars[1].future = totalConsumption(weekPrediction[1]) - weekBars[1].value;
  weekBars[2].future = totalConsumption(weekPrediction[2]) - weekBars[2].value;
  weekBars[3].future = totalConsumption(weekPrediction[3]) - weekBars[3].value;
  weekBars[4].future = totalConsumption(weekPrediction[4]) - weekBars[4].value;
  weekBars[5].future = totalConsumption(weekPrediction[5]) - weekBars[5].value;
  weekBars[6].future = totalConsumption(weekPrediction[6]) - weekBars[6].value;

  const { hightTariff, lowTariff } = calcHightAndLowTariff(weekPrediction, weekBars, state.ui.timeline);

  const line = (range, key, name, data) => lineData(range.start, range.end, name, graphData(data, key, name)[0], 900);

  return {
    currentTime: state.ui.currentTime,
    selectedDate: state.ui.timeline.selected,
    timeline: state.ui.timeline,
    inProgress: state.api.pending.length > 0,
    dataInterval,

    dayLine: line(day, 'value', 'day', electricityDay, 900),
    dayFLine: line(day, 'value', 'dayf', electricityDayPrediction, 900),
    prevDayLine: line(previousDay, 'value', 'prev', electricityYesterday, 900),
    prevDayFLine: line(previousDay, 'value', 'prevf', electricityYesterdayPrediction, 900),

    weekBars,
    hightTariff,
    lowTariff,

    selectedAvgConsumption,
    selectedTime: quarterHour.start,
    selectedData: electricitySelectedData,
    isSelectedPrediction,
    selectedTotalConsumption: totalConsumption(
      sumByTime(between(electricitySelectedDay, day.start, moment(day.end).subtract(1, 's'))),
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getElectricityData: getElectricityData(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Electricity);
