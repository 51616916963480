import { unique, removeDuplicateTimestamps } from 'utils';

export const getExistingAndNewIds = (stateMeters, meters) => {
  const reducer = (a, meter) => {
    a.push(meter.meter);

    return a;
  };

  const existingIds = stateMeters.reduce(reducer, []);
  const newIds = meters.reduce(reducer, []);

  return unique(existingIds.concat(newIds));
};

export const returnLoadingState = (state) => ({
  ...state,
  loading: true,
});

export const addNewMetersToState = (state, meters, loading = false) => ({
  ...state,
  loading,
  meters: getExistingAndNewIds(state.meters, meters).map((id) => {
    const meter = meters.find((m) => m.meter === id);

    if (!meter) {
      return state.meters.find((m) => m.meter === id);
    }

    const existingReadings = state.meters.reduce((readings, m) => {
      if (id === m.meter) {
        return m.readings;
      }

      return readings;
    }, []);

    const newReadings = meters.reduce((readings, m) => {
      if (id === m.meter) {
        return m.readings;
      }

      return readings;
    }, []);

    const readings = removeDuplicateTimestamps([...existingReadings, ...newReadings]);

    return {
      ...meter,
      readings,
    };
  }),
});

export const replaceMeters = (state, meters) => ({
  ...state,
  loading: false,
  meters,
});
