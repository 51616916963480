/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { selectWeekData, selectLineData, selectDayAverage, selectCurrentData } from 'redux/selectors';
import { getCo2Data } from 'redux/services';

import { dataRange, printValue, formatSelectedInterval } from 'utils';
import * as units from 'utils/units';
import { combineLines } from 'components/charts/LineChart';
import { Page, Sidebar, Main, Map, BackToHomeLink, LineChart, BarChart, InfoBubble, PinCo2 as Pin } from 'components';

import styles from './Co2.module.scss';

const key = 'value';

const lineSettings = [
  { key: 'day', label: 'Izpust CO<sub>2</sub>', color: '#FFD400' },
  { key: 'dayf', label: 'Izpust CO<sub>2</sub>', color: '#FFF2B3' },
];
const barSettings = [
  { key: 'future', stackId: 'day' },
  { key: 'value', stackId: 'day' },
];

const Co2 = (props) => {
  const { lineToday, lineFuture, weekBars, selectedTime, dataInterval } = props;
  const timestamp = moment(selectedTime).valueOf();

  const [lineGraphData, setLineGraphData] = useState([]);
  const [barGraphData, setBarGraphData] = useState([]);

  useEffect(() => {
    props.getApiData(dataInterval.start, dataInterval.end);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInterval.start, dataInterval.end]);

  useEffect(() => {
    setLineGraphData(combineLines(lineToday, lineFuture));
    setBarGraphData(weekBars);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineToday, lineFuture]);

  return (
    <Page>
      <Sidebar>
        <BackToHomeLink />
        <h1>
          Izpust co<sub>2</sub>
        </h1>
        <p>
          Prikaz <b>testnih</b> podatkov v merilnem intervalu 1h.
        </p>
        <h2>
          Predviden dnevni izpust CO<sub>2</sub>
        </h2>
        <span className="text-large">{printValue(props.sum, units.co2)}</span>
        <h2>
          Dnevni trend izpustov CO<sub>2</sub>
        </h2>
        <div className={styles.lineGraph}>
          <LineChart
            data={lineGraphData}
            lines={lineSettings}
            currentTimestamp={timestamp}
            loading={props.inProgress}
            reference={{ point: props.avg, label: 'Povprečni izpust' }}
            units={units.co2}
          />
        </div>
        <h2>
          tedenski izpust CO<sub>2</sub>
        </h2>

        <BarChart
          data={barGraphData}
          bars={barSettings}
          currentTimestamp={timestamp}
          loading={props.inProgress}
          units={units.co2}
        />
      </Sidebar>
      <Main>
        <Map step={3600}>
          {props.selectedData.map((meter) => {
            const a = meter.readings.length ? meter.readings[0][key] : 0;

            const cName = ['readings', props.isSelectedPrediction ? 'predictionData' : 'realData'];

            return (
              <InfoBubble
                key={meter.meter}
                lng={meter.location.longitude}
                lat={meter.location.latitude}
                zoom={1}
                icon={<Pin />}
                hover
              >
                <h3>
                  Izpust co<sub>2</sub> <span>{formatSelectedInterval(timestamp)}</span>
                </h3>
                <div className={cName.join(' ')}>
                  <span className="label">
                    Izpust CO<sub>2</sub>:
                  </span>
                  <span className="value">{printValue(a, units.co2)}</span>
                </div>
              </InfoBubble>
            );
          })}
        </Map>
      </Main>
    </Page>
  );
};

const mapStateToProps = (state) => {
  const { hour, dayIndex } = state.ui.timeline;

  const [isSelectedPrediction, redoutHourData] = selectCurrentData(state, 'co2', undefined, undefined, 3600);
  const weekBars = selectWeekData(state, 'co2', key);
  const [lineToday, lineFuture] = selectLineData(state, 'co2', key, 'day');

  return {
    currentTime: state.ui.currentTime,
    selectedDate: state.ui.timeline.selected,
    timeline: state.ui.timeline,
    inProgress: state.api.pending.length > 0,
    selectedTime: hour.start,
    isSelectedPrediction,
    dataInterval: dataRange(state.ui.timeline, state.ui.timeline.selected),

    avg: selectDayAverage(state, 'co2', key),
    sum: weekBars[dayIndex].value + weekBars[dayIndex].future,
    lineToday,
    lineFuture,
    weekBars,

    selectedData: redoutHourData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getApiData: getCo2Data(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Co2);
