import { API_REQUEST_STARTED, API_REQUEST_FINISH, API_REQUEST_ERROR, API_REQUEST_ERROR_REMOVE } from '../../actions';

export const initialState = {
  pending: [],
  errors: [],
};

export const apiReducer = (state = initialState, action = { type: undefined, payload: '' }) => {
  switch (action.type) {
    case API_REQUEST_STARTED:
      return {
        ...state,
        pending: [...state.pending, action.payload],
      };

    case API_REQUEST_FINISH:
      return {
        ...state,
        pending: state.pending.filter((p) => p !== action.payload),
      };

    case API_REQUEST_ERROR:
      return {
        ...state,
        errors: [...state.errors, action.payload],
      };

    case API_REQUEST_ERROR_REMOVE:
      return {
        ...state,
        errors: state.errors.filter((e) => e !== action.payload),
      };

    default:
      return state;
  }
};
