import { addNewMetersToState, returnLoadingState, replaceMeters } from '../utils';
import { FETCH_GAS, APPEND_GAS, SET_GAS } from '../../actions';

export const initialState = {
  loading: false,
  meters: [],
};

export const gasReducer = (state = initialState, action = { type: undefined, payload: [] }) => {
  switch (action.type) {
    case FETCH_GAS:
      return returnLoadingState(state);

    case APPEND_GAS:
      return addNewMetersToState(state, action.payload);

    case SET_GAS:
      return replaceMeters(state, action.payload);

    default:
      return state;
  }
};
