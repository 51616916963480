import moment from 'moment';
import { selectBetween, selectAveragePerMeter, selectMaxPerMeter, selectSumPerMeter } from '.';

/**
 *
 * @param {array} meters - use selectMeters()
 * @param {string} timestamp
 * @param {int} step - data interval in seconds defaults to 15min (900)
 */
export const selectByTime = (meters, timestamp, step = 900) => {
  if (!meters.length) return meters;

  const unix = moment(timestamp).unix();
  const diff = unix % step;
  const prevPoint = unix - diff;

  const start = moment(prevPoint * 1000);
  const end = start.clone().add(step - 1, 'seconds');

  return selectBetween(meters, start, end);
};

/**
 *
 * @param {array} meters - use selectMeters()
 * @param {string} timestamp  - any timestamp in the day
 */
export const selectByDate = (meters, timestamp, inclusive = true) => {
  if (!meters.length) return meters;

  const start = moment(timestamp).startOf('day');
  const end = start.clone().add(1, 'days');

  if (!inclusive) end.subtract(1, 'seconds');

  return selectBetween(meters, start, end);
};

/**
 *
 * @param {array} meters - use selectMeters()
 * @param {string} key
 */
export const selectAverage = (meters, key) => {
  if (!meters.length) return 0;

  const averageByMeter = selectAveragePerMeter(meters, key);

  return averageByMeter.reduce((avg, meter) => avg + meter.readings[0][key], 0) / averageByMeter.length;
};

/**
 *
 * @param {array} meters - use selectMeters()
 * @param {string} key
 */
export const selectMax = (meters, key) => {
  if (!meters.length) return 0;

  const maxByMeter = selectMaxPerMeter(meters, key);

  return maxByMeter.reduce((max, meter) => (max > meter.readings[0][key] ? max : meter.readings[0][key]), 0);
};

/**
 *
 * @param {array} meters - use selectMeters()
 * @param {string} key
 */
export const selectSum = (meters, key) => {
  if (!meters.length) return 0;

  const sumByMeter = selectSumPerMeter(meters, key);

  return sumByMeter.reduce((sum, meter) => sum + meter.readings[0][key], 0);
};
