import { GOT_CO2, setCo2, setMeters } from '../../actions';

export const co2Mdl = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === GOT_CO2) {
    /*
     * Transform data to common format
     */
    const normalizedMeters = action.payload.meters.map((meter) => ({
      ...meter,
      interval: undefined,
    }));

    const metersData = normalizedMeters.map((meter) => ({
      meter: meter.meter,
      location: meter.location,
      type: 'co2',
    }));

    dispatch(setCo2(normalizedMeters));
    dispatch(setMeters(metersData));
  }
};
