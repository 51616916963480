import React, { useState, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import GoogleMaps from 'google-map-react';
import { Timeline } from 'components';

import styles from './Map.module.scss';
import mapStyles from './styles.json';

const Map = (props) => {
  const step = props.step || 900;
  const defaultZoom = 16;
  const center = [46.271, 14.352];
  const showTimeline = !props.withoutTimeline;
  const [mapZoom, setZoom] = useState(defaultZoom);

  const mapOptions = (maps) => ({
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_TOP,
    },
  });
  const setMapStyles = (map, maps) => {
    const styledMapType = new maps.StyledMapType(mapStyles);

    map.mapTypes.set('styled_map', styledMapType);
    map.setMapTypeId('styled_map');
  };
  const onLoad = ({ map, maps }) => {
    setMapStyles(map, maps);

    if (props.drawOnMap) {
      props.drawOnMap({ map, maps });
    }
  };

  const onChange = ({ zoom }) => {
    setZoom(zoom);
  };

  const elements = Children.toArray(props.children).map((el) => cloneElement(el, { zoom: mapZoom }));

  return (
    <>
      <div className={styles.container}>
        <div className={styles.map}>
          <GoogleMaps
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_KEY }}
            defaultCenter={center}
            defaultZoom={defaultZoom}
            options={mapOptions}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={onLoad}
            onChange={onChange}
          >
            {elements}
          </GoogleMaps>
          <div className={styles.timeline}>{showTimeline && <Timeline step={step} />}</div>
        </div>
      </div>
    </>
  );
};

Map.propTypes = {
  children: PropTypes.node,
  drawOnMap: PropTypes.func,
  step: PropTypes.number,
};

export default Map;
