import { GOT_ELECTRICITY, setElectricity, setMeters } from '../../actions';

export const electricityMdl = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === GOT_ELECTRICITY) {
    /*
     * Transform data to common format
     */
    const normalizedMeters = action.payload.meters
      .filter((meter) => meter.meter === 'Mlaka SKUPAJ')
      .map((meter) => ({
        ...meter,
        interval: undefined,
        readings: meter.readings.reduce((accumulator, reading) => {
          const minutes = reading.timestamp.substr(14, 2);

          // Make sure interval is 15min
          if (minutes === '00' || minutes === '15' || minutes === '30' || minutes === '45') {
            accumulator.push(reading);
          }

          return accumulator;
        }, []),
      }));
    const metersData = normalizedMeters.map((meter) => ({
      meter: meter.meter,
      location: meter.location,
      type: 'electricity',
    }));

    dispatch(setElectricity(normalizedMeters));
    dispatch(setMeters(metersData));
  }
};
