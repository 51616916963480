export const electricity = [
  { unit: ' MWh', divider: 1000000 },
  { unit: ' kWh', divider: 1000 },
  { unit: ' Wh', divider: 1 },
];
export const co2 = [
  { unit: ' t', divider: 1000000 },
  { unit: ' kg', divider: 1000 },
  { unit: ' g', divider: 1 },
];
export const gas = [{ unit: ' m<sup>3</sup>', divider: 1 }];
export const temperature = [{ unit: '°C', divider: 1 }];
export const humidity = [{ unit: '%', divider: 1 }];
export const air = [{ unit: ' ug/m<sup>3</sup>', divider: 1 }];
export const speed = [{ unit: ' km/h', divider: 1 }];
export const windDirection = [{ unit: '°', divider: 1 }];
export const noise = [{ unit: 'dB', divider: 1 }];
export const water = [
  { unit: ' m<sup>3</sup>', divider: 1000 },
  { unit: ' l', divider: 1 },
];
