import browserUpdate from 'browser-update';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';

import App from './App';
import * as serviceWorker from './serviceWorker';

browserUpdate({
  required: {
    e: -2,
    f: -2,
    o: -2,
    s: -2,
    c: -2,
  },
  l: 'sl',
});

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
