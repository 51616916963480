import { API_REQUEST, apiStart, apiFinish, apiError } from '../../actions';
import { instance } from './axiosInstance';

export const apiMdl = ({ dispatch }) => (next) => async (action) => {
  next(action);

  if (action.type === API_REQUEST) {
    const { path, method = 'get', filters = {} } = action.payload;
    const entity = path
      .split('/')
      .map((v) => v.charAt(0).toUpperCase() + v.slice(1))
      .join('');

    const requestSignature = { entity, payload: action.payload };

    dispatch(apiStart(requestSignature));
    dispatch(action.callbacks.init());

    await instance[method](path, { params: filters })
      .then((response) => {
        dispatch(action.callbacks.onLoad(response.data));
      })
      .catch((error) => {
        dispatch(apiError(error, action));
      })
      .finally(() => {
        dispatch(apiFinish(requestSignature));
      });
  }
};
