/* eslint-disable max-lines-per-function */
import React from 'react';
import { connect } from 'react-redux';

import { apiErrorRemove, apiRequest } from 'redux/actions';
import { Close } from 'components';

import styles from './ErrorHandler.module.scss';

const ErrorHandler = (props) => {
  const translateErrorMessage = (msg) => {
    const translations = {
      'Request failed with status code 500': 'Strežnik se ne odziva (500)',
      'Request failed with status code 401': 'Napačen API ključ (401)',
    };

    if (translations[msg]) {
      return translations[msg];
    }

    return msg;
  };

  // eslint-disable-next-line no-unused-vars
  const retry = (e) => {
    props.clearError(e);
    props.retry(e);
  };

  return (
    <>
      {props.api.pending.length !== 0 && <div className={styles.loading}></div>}
      {props.api.errors.length !== 0 && (
        <div className={styles.error}>
          <ul>
            {props.api.errors.map((e, index) => (
              <li key={`${e.action.payload.path}-${index}`}>
                Prišlo je do napake: {translateErrorMessage(e.error.message)}{' '}
                {/* <span onClick={() => retry(e)}>Poiskusi znova</span> */}
                <span onClick={() => props.clearError(e)}>
                  <Close />
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  api: state.api,
});

const mapDispatchToProps = (dispatch) => ({
  clearError: (error) => {
    dispatch(apiErrorRemove(error));
  },
  retry: (error) => {
    dispatch(apiRequest(error.action.payload, error.action.callbacks.init, error.action.callbacks.onLoad));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorHandler);
