import React from 'react';
import { Link } from 'react-router-dom';
import { PinElectricity, PinSpeed, PinLight, PinAir } from 'components';

import styles from './Navigation.module.scss';

export const routerPaths = {
  home: { path: '/' },
  electricity: { path: '/elektrika' },
  lights: { path: '/osvetlitev' },
  traffic: { path: '/promet' },
  air: { path: '/zrak' },
  rodent: { path: '/glodavci' },
  water: { path: '/voda' },
  cycling: { path: '/kolesarji' },
  package: { path: '/paketi' },
  gas: { path: '/plin' },
  co2: { path: '/co2' },
};

const Navigation = () => (
  <ul className={styles.container}>
    <li>
      <Link to={routerPaths.electricity.path}>
        <PinElectricity />
      </Link>
    </li>
    <li>
      <Link to={routerPaths.lights.path}>
        <PinLight />
      </Link>
    </li>
    <li>
      <Link to={routerPaths.traffic.path}>
        <PinSpeed />
      </Link>
    </li>
    <li>
      <Link to={routerPaths.air.path}>
        <PinAir />
      </Link>
    </li>
  </ul>
);

export default Navigation;
