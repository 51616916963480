import moment from 'moment';
import { sortBy } from 'utils';

/**
 *
 * @param {object} storeState - redux state
 */
export function selectMeters(storeState) {
  return storeState.meters;
}

/**
 *
 * @param {array} meters - use selectMeters()
 */
export function selectFirstMeter(meters) {
  if (!meters.length) return meters;

  return [meters[0]];
}

/**
 *
 * @param {array} meters - use selectMeters()
 */
export function lastTimestamp(meters) {
  if (!meters.length) return 0;

  return meters[0].readings.reduce((max, reading) => {
    const value = moment(reading.timestamp).valueOf();

    if (value > max) {
      return value;
    }

    return max;
  }, 0);
}

/**
 *
 * @param {array} meters - use selectMeters()
 */
export function hasSufficientData(meters, minLength) {
  if (!meters.length) return false;

  return meters[0].readings.length >= minLength;
}

/**
 *
 * @param {array} meters - use selectMeters()
 * @param {string} id - redux state
 */
export function selectMeterById(meters, id) {
  const m = meters.filter((meter) => meter.meter === id);

  return m ? m[0] : { readings: [] };
}

/**
 *
 * @param {array} meters - use selectMeters()
 * @param {string} id - redux state
 */
export function selectReadingByTimestamp(readings, timestamp) {
  const r = readings.filter((reading) => reading.timestamp === timestamp);

  return r ? r[0] : undefined;
}

/**
 *
 * @param {array} meters - use selectMeters()
 * @param {string} startTimestamp
 */
export const selectBetween = (meters, start, end) => {
  if (!meters.length) return meters;

  return meters.map((meter) => {
    const filterFn = (reading) => moment(reading.timestamp).isBetween(start, end, undefined, '[]');
    const readings = meter.readings.filter(filterFn);

    return {
      ...meter,
      readings: sortBy(readings, 'timestamp'),
    };
  });
};

/**
 *
 * @param {array} meters use selectMeters()
 * @param {string} key - Readings object key
 * @param {string} name - Returned object key
 */
export const selectGraphData = (meters, key, name = 'value') => {
  if (!meters.length) return meters;

  const values = meters.map((meter) => {
    const readings = meter.readings.map((reading) => ({
      [name]: reading[key],
      time: moment(reading.timestamp).valueOf(),
    }));

    return readings;
  });

  return values;
};

/**
 *
 * @param {array} meters - use selectMeters()
 * @param {string} timestamp  - any timestamp in the day
 */
export const selectAveragePerMeter = (meters, key) => {
  if (!meters.length) return [0];

  return meters.map((meter) => {
    const sum = meter.readings.reduce((s, reading) => {
      const total = s + reading[key] || 0;

      return total;
    }, 0);

    const avg = meter.readings.length ? sum / meter.readings.length : 0;

    return {
      ...meter,
      readings: [
        {
          [key]: avg,
        },
      ],
    };
  });
};

/**
 *
 * @param {array} meters - use selectMeters()
 * @param {string} key
 */
export const selectMaxPerMeter = (meters, key) => {
  if (!meters.length) return [0];

  return meters.map((meter) => {
    const max = meter.readings.reduce((m, reading) => {
      if (m < reading[key]) {
        return reading[key];
      }

      return m;
    }, 0);

    return {
      ...meter,
      readings: [
        {
          [key]: max,
        },
      ],
    };
  });
};

/**
 *
 * @param {array} meters - use selectMeters()
 * @param {string} key
 */
export const selectSumPerMeter = (meters, key) => {
  if (!meters.length) return [0];

  return meters.map((meter) => {
    const sum = meter.readings.reduce((s, reading) => {
      let total = s;

      if (reading[key]) {
        total += reading[key];
      }

      return total;
    }, 0);

    return {
      ...meter,
      readings: [
        {
          [key]: sum,
        },
      ],
    };
  });
};

export const selectSumByTime = (meters, key) => {
  if (!meters.length) return meters;

  const allReadings = sortBy(
    meters.reduce((readings, meter) => readings.concat(meter.readings), []),
    'timestamp',
  );

  const sumByTimestamp = allReadings.reduce((sum, reading) => {
    const duplicate = sum.findIndex((r) => reading.timestamp === r.timestamp);

    if (duplicate === -1) {
      sum.push({ ...reading });
    } else {
      for (const k in reading) {
        if (reading.hasOwnProperty(k)) {
          if (k !== 'timestamp') {
            sum[duplicate][k] += reading[k] ? reading[k] : 0; // Fix for undefined values
          }
        }
      }
    }

    return sum;
  }, []);

  return [
    {
      location: undefined,
      meter: 'SUM',
      readings: sumByTimestamp,
    },
  ];
};

export const selectAvgByTime = (meters, key) => {
  if (!meters.length) return meters;

  const metersLength = meters.length;

  const meterSum = selectSumByTime(meters, key);

  const avgReadings = meterSum[0].readings.map((reading) => {
    const avg = {
      timestamp: reading.timestamp,
    };

    for (const k in reading) {
      if (reading.hasOwnProperty(k)) {
        if (k !== 'timestamp') {
          avg[k] = reading[k] / metersLength;
        }
      }
    }

    return avg;
  });

  return [
    {
      location: undefined,
      meter: 'AVG',
      readings: avgReadings,
    },
  ];
};

export * from './composed';
export * from './future';
export * from './graph';
