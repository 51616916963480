import { addNewMetersToState, returnLoadingState, replaceMeters } from '../utils';
import { FETCH_AIR, APPEND_AIR, SET_AIR } from '../../actions';

export const initialState = {
  loading: false,
  meters: [],
};

export const airReducer = (state = initialState, action = { type: undefined, payload: [] }) => {
  switch (action.type) {
    case FETCH_AIR:
      return returnLoadingState(state);

    case APPEND_AIR:
      return addNewMetersToState(state, action.payload);

    case SET_AIR:
      return replaceMeters(state, action.payload);

    default:
      return state;
  }
};
