import React from 'react';
import PropTypes from 'prop-types';

import styles from './InfoBubble.module.scss';

const InfoBubble = (props) => {
  const cNames = [styles.container, props.hover ? styles.hoverOnly : ''];

  return (
    <div className={cNames.join(' ')}>
      {props.icon}
      <div className={styles.infoBubble}>{props.children}</div>
    </div>
  );
};

InfoBubble.propTypes = {
  zoom: PropTypes.number.isRequired,
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default InfoBubble;
