import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/sl';

import { ChevronLeft, ChevronRight } from 'components';

import styles from './DatePicker.module.scss';

const DatePicker = (props) => {
  const date = moment(props.start).startOf('day');

  const formatDate = (dateToFormat) => dateToFormat.locale('sl').format('DD MMM YYYY');

  const changeDate = (value) => {
    props.onChange(value.valueOf());
  };

  const nextDay = () => {
    const newDate = date.add(1, 'days');

    changeDate(newDate);
  };

  const prevDay = () => {
    const newDate = date.subtract(1, 'days');

    changeDate(newDate);
  };

  return (
    <div className={styles.container}>
      <button onClick={prevDay} className={styles.button}>
        <ChevronLeft />
      </button>
      <span className={styles.current}>{formatDate(date)}</span>
      <button onClick={nextDay} className={styles.button}>
        <ChevronRight />
      </button>
    </div>
  );
};

DatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  start: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default DatePicker;
