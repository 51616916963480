import React from 'react';
import { XAxis as ReXAxis } from 'recharts';

import { ticks } from './ticks';

const RotatedLabel = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={4} textAnchor="end" transform="rotate(-90)">
        {payload.value}
      </text>
    </g>
  );
};

export default class XAxisHours extends ReXAxis {
  static defaultProps = {
    ...ReXAxis.defaultProps,
    ticks,
    interval: 0,
    tick: RotatedLabel,
  };
}
