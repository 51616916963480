import { XAxis as ReXAxis } from 'recharts';

import { ticks } from './ticks';

export default class XAxisDays extends ReXAxis {
  static defaultProps = {
    ...ReXAxis.defaultProps,
    ticks,
    interval: 0,
    tick: { fill: '#10161a' },
  };
}
