export const FETCH_LIGHTS = 'FETCH_LIGHTS';
export const APPEND_LIGHTS = 'APPEND_LIGHTS';
export const SET_LIGHTS = 'SET_LIGHTS';

export const fetchLights = () => ({
  type: FETCH_LIGHTS,
});

export const appendLights = (data) => ({
  type: APPEND_LIGHTS,
  payload: data,
});

export const setLights = (data) => ({
  type: SET_LIGHTS,
  payload: data,
});
