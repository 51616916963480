export const FETCH_WATER = 'FETCH_WATER';
export const GOT_WATER = 'GOT_WATER';
export const APPEND_WATER = 'APPEND_WATER';
export const SET_WATER = 'SET_WATER';

export const fetchWater = () => ({
  type: FETCH_WATER,
});

export const gotWater = (data) => ({
  type: GOT_WATER,
  payload: data,
});

export const appendWater = (data) => ({
  type: APPEND_WATER,
  payload: data,
});

export const setWater = (data) => ({
  type: SET_WATER,
  payload: data,
});
