import moment from 'moment';

export function startOfToday() {
  // eslint-disable-next-line newline-per-chained-call
  return moment().startOf('day').valueOf();
}

export function currentTimestamp() {
  // eslint-disable-next-line newline-per-chained-call
  return moment().valueOf();
}

export const isInFuture = (time) => {
  const current = moment().unix();
  const future = moment(time).unix();

  return current < future;
};

export const dataRange = (times, selected) => {
  const endOfLastFullWeek = moment().startOf('isoWeek');

  // default to last 2 weeks.
  let start = endOfLastFullWeek.clone().subtract(1, 'week');
  let end = endOfLastFullWeek.clone().add(1, 'week').format();

  // If selection is before last week we should have all data
  if (moment(selected).unix() < endOfLastFullWeek.unix()) {
    start = times.week.start;
    end = times.week.end;
  }

  return {
    start: moment(start).subtract(1, 'days').format(),
    end,
  };
};

export const formatSelectedInterval = (time, interval = 3600) => {
  const from = moment(time).subtract(interval, 'seconds').format('HH:mm');
  const to = moment(time).format('HH:mm');

  return `(${from} - ${to})`;
};
