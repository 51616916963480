export const FETCH_PACKAGES = 'FETCH_PACKAGES';
export const GOT_PACKAGES = 'GOT_PACKAGES';
export const APPEND_PACKAGES = 'APPEND_PACKAGES';
export const SET_PACKAGES = 'SET_PACKAGES';

export const fetchPackages = () => ({
  type: FETCH_PACKAGES,
});

export const gotPackages = (data) => ({
  type: GOT_PACKAGES,
  payload: data,
});

export const appendPackages = (data) => ({
  type: APPEND_PACKAGES,
  payload: data,
});

export const setPackages = (data) => ({
  type: SET_PACKAGES,
  payload: data,
});
