import React from 'react';
import { Page, BackToHomeLink, Main, Navigation } from 'components';

import graphic from 'assets/graphic/404-graphic.png';

import styles from './Error.module.scss';

const Error = () => (
  <Page>
    <Main>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <img src={graphic} alt="" />
          <h1>Area 404</h1>
          <p>Željena stran ne obstaja. Pojdite nazaj ali izberite eno izmed podstrani.</p>
          <Navigation />
          <BackToHomeLink />
        </div>
      </div>
    </Main>
  </Page>
);

export default Error;
