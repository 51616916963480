import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { selectWeekData, selectLineData, selectDayAverage, selectCurrentData } from 'redux/selectors';
import { getEnvironmentData } from 'redux/services';

import { dataRange, emptyData, printValue, formatSelectedInterval } from 'utils';
import * as units from 'utils/units';
import { combineLines } from 'components/charts/LineChart';
import { Page, Sidebar, Main, Map, BackToHomeLink, LineChart, InfoBubble, PinLight } from 'components';

import styles from './Lights.module.scss';

const totalPowerLineSettings = [
  { key: 'day', label: 'Izbrani dan', color: '#FFD400' },
  { key: 'prev', label: 'Dan pred tem', color: '#3B6590' },
  { key: 'dayf', label: 'Izbrani dan', color: '#FFF2B3' },
  { key: 'prevf', label: 'Dan pred tem', color: '#BACADA' },
];

const Lights = (props) => {
  const { totalPowerTLine, totalPowerYLine, totalPowerFLine, totalPowerYFLine, selectedTime, dataInterval } = props;
  const timestamp = moment(selectedTime).valueOf();

  const [totalPowerData, setTotalPowerData] = useState([]);

  useEffect(() => {
    props.getEnvironmentData(dataInterval.start, dataInterval.ens);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInterval.start, dataInterval.ens]);

  useEffect(() => {
    setTotalPowerData(combineLines(totalPowerTLine, totalPowerYLine, totalPowerFLine, totalPowerYFLine));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPowerTLine, totalPowerYLine, totalPowerFLine, totalPowerYFLine]);

  return (
    <Page>
      <Sidebar>
        <BackToHomeLink />
        <h1>Osvetlitev</h1>
        <p>Izmera porabe električne energije v merilnem intervalu 1h</p>
        <h2>Predvidena dnevna poraba električne energije</h2>
        <span className="text-large">{printValue(props.totalPower, units.electricity)}</span>
        <h2>Dnevni trend porabe električne energije</h2>
        <div className={styles.hideDuplicatedAvg}>
          <LineChart
            data={totalPowerData}
            lines={totalPowerLineSettings}
            currentTimestamp={timestamp}
            loading={props.inProgress}
            reference={{ point: props.avgPower, label: 'Povprečna poraba' }}
            units={units.electricity}
            noData={emptyData(totalPowerData, totalPowerLineSettings[0].key, props.selectedDate)}
          />
        </div>
      </Sidebar>
      <Main>
        <Map step={3600}>
          {props.selectedData.map((meter) => {
            const power = meter.readings.length ? meter.readings[0].activePower : 0;
            const level = meter.readings.length ? meter.readings[0].dimmingLevel : 0;
            const cName = ['readings', props.isSelectedPrediction ? 'predictionData' : 'realData'];

            return (
              <InfoBubble
                key={meter.meter}
                lng={meter.location.longitude}
                lat={meter.location.latitude}
                zoom={1}
                icon={<PinLight />}
                hover
              >
                <h3>
                  Osvetlitev <span>{formatSelectedInterval(timestamp)}</span>
                </h3>
                <div className={cName.join(' ')}>
                  <span className="label">Poraba:</span>
                  <span className="value">{printValue(power, units.electricity)}</span>

                  <span className="label">Svetilnost:</span>
                  <span className="value">{level} %</span>
                </div>
              </InfoBubble>
            );
          })}
        </Map>
      </Main>
    </Page>
  );
};

const mapStateToProps = (state) => {
  const { hour, selected, dayIndex } = state.ui.timeline;
  const dataInterval = dataRange(state.ui.timeline, selected);

  const [isSelectedPrediction, redoutHourData] = selectCurrentData(state, 'lights', undefined, undefined, 3600);
  const [totalPowerTLine, totalPowerFLine] = selectLineData(state, 'lights', 'activePower', 'day');
  const [totalPowerYLine, totalPowerYFLine] = selectLineData(state, 'lights', 'activePower', 'prev', undefined, true);
  const weekBars = selectWeekData(state, 'lights', 'activePower');

  return {
    currentTime: state.ui.currentTime,
    selectedDate: state.ui.timeline.selected,
    timeline: state.ui.timeline,
    inProgress: state.api.pending.length > 0,
    dataInterval,

    totalPowerTLine,
    totalPowerFLine,
    totalPowerYLine,
    totalPowerYFLine,

    totalPower: weekBars[dayIndex].value,
    avgPower: selectDayAverage(state, 'lights', 'activePower'),

    selectedTime: hour.start,
    selectedData: redoutHourData,
    isSelectedPrediction,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEnvironmentData: getEnvironmentData(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Lights);
