import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ResponsiveContainer, BarChart as Chart, Bar, ReferenceLine, LabelList } from 'recharts';

import { Loading } from 'components';
import { formatNumber, formatValue } from 'utils';

import { XAxisDays as XAxis } from '..';
import styles from './BarChart.module.scss';

const Label = (props) => {
  const { x, y, width, hover } = props;

  if (props.value <= 0) return <g></g>;

  const cNameValue = props.isFuture ? styles.labelValueFuture : styles.labelValue;
  const cNameUnit = props.isFuture ? styles.labelUnitFuture : styles.labelUnit;

  const renderHtml = (html) => {
    const replacements = {
      '<sup>': '<tspan baseline-shift="super">',
      '</sup>': '</tspan>',
      '<sub>': '<tspan baseline-shift="sub">',
      '</sub>': '</tspan>',
    };

    return html.replace(/<sup>|<\/sup>|<sub>|<\/sub>/giu, (matched) => replacements[matched]);
  };

  let show = false;

  if (hover && hover.x === x && hover.y === y) show = true;

  let unit = false;
  let value = props.value;

  if (props.units) {
    [value, unit] = formatValue(props.value, props.units);
  } else if (props.unit) {
    unit = props.unit;
    value = formatNumber(value);
  }

  return (
    <g>
      {show && (
        <>
          <text x={x + width / 2} y={y} dy={-20} textAnchor="middle" className={cNameValue}>
            {value}
          </text>
          {unit && (
            <text
              x={x + width / 2}
              y={y}
              dy={-4}
              textAnchor="middle"
              className={cNameUnit}
              dangerouslySetInnerHTML={{ __html: renderHtml(unit) }}
            ></text>
          )}
        </>
      )}
    </g>
  );
};

const BarChart = (props) => {
  const defaultColor = ['#C4D8ED', '#8AB1DB'];

  const currentTime = props.currentTimestamp;
  const currentStep = currentTime - (currentTime % 900000);
  const currentReference = moment(currentStep).format('H:mm');
  const [hover, setHover] = useState(null);

  const render = () => {
    switch (true) {
      case props.loading:
        return <Loading />;

      case props.noData:
      // TODO: temporaraly removed for demo return <NoData />;

      // eslint-disable-next-line: no-fallthrough
      default:
        return (
          <Chart
            data={props.data}
            margin={{ top: 50, right: 0, bottom: 0, left: 0 }}
            barCategoryGap={0}
            barGap={3}
            reverseStackOrder
          >
            <XAxis dataKey="day" />
            <ReferenceLine x={currentReference} stroke="#E62323" />

            {props.bars.map((settings, index) => (
              <Bar
                isAnimationActive={false}
                key={settings.key}
                dataKey={settings.key}
                name={settings.label}
                fill={settings.color || defaultColor[index] || defaultColor[0]}
                radius={[3, 3, 0, 0]}
                stackId={settings.stackId || index}
                onMouseEnter={(x, i) => {
                  setHover(x);
                }}
                onMouseLeave={() => {
                  setHover(null);
                }}
              >
                <LabelList
                  dataKey={settings.key}
                  content={(prop) => (
                    <Label
                      hover={hover}
                      isFuture={settings.key === 'future'}
                      unit={props.unit}
                      units={props.units}
                      {...prop}
                    />
                  )}
                  position={'top'}
                />
              </Bar>
            ))}
          </Chart>
        );
    }
  };

  return (
    <div className={styles.barChart}>
      <ResponsiveContainer height={260}>{render()}</ResponsiveContainer>
    </div>
  );
};

BarChart.propTypes = {
  currentTimestamp: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  bars: PropTypes.array.isRequired,
  data: PropTypes.array,
  unit: PropTypes.string,
  noData: PropTypes.bool,
  units: PropTypes.array,
};

BarChart.defaultProps = {
  unit: '',
  noData: false,
};

export default BarChart;
