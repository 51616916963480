export function unique(array) {
  return array.filter((item, index) => array.indexOf(item) === index);
}

/**
 *
 * @param {array} array - in format {timestamp: string, [string]: any}
 */
export function removeDuplicateTimestamps(array) {
  return array.reduce((existing, i) => {
    const exists = existing.some((e) => e.timestamp === i.timestamp);

    if (!exists) {
      existing.push(i);
    }

    return existing;
  }, []);
}
