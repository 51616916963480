import moment from 'moment';
import { sortBy } from 'utils';

export const fillEmptySlots = (interval, start, end, key, data = []) => {
  for (let time = start; time <= end; time += interval) {
    if (!data.some((reading) => reading.time === time)) {
      data.push({
        time,
        [key]: undefined,
      });
    }
  }

  return sortBy(data, 'time');
};

export const formatData = (data) => {
  const values = data.map((row) => ({
    ...row,
    time: moment(row.time).format('H:mm'),
  }));

  // Fix issue with duplicated values for 0:00
  if (values[values.length - 1].time === '0:00') {
    values[values.length - 1].time = '24:00';
  }

  return values;
};

export const combineLines = (...lines) => {
  const combined = lines.reduce((accumulator, line) => {
    line.forEach((reading, index) => {
      if (accumulator[index]) {
        accumulator[index] = {
          ...accumulator[index],
          ...reading,
        };
      } else {
        accumulator.push(reading);
      }
    });

    return accumulator;
  }, []);

  return combined;
};

/**
 * Fills in gaps in readings with last known value.
 * first and last gap will remain
 *
 * @param {array} data
 */
export const duplicateReadings = (data, key) => {
  let lastIndex = -1;
  let previousValue;

  data.forEach((row, index) => {
    if (row[key] !== undefined) {
      lastIndex = index;
    }
  });

  data.forEach((row, index) => {
    if (row[key] !== undefined) {
      // previousValue = row[key];
      previousValue = 0;
    }
    if (row[key] === undefined && index < lastIndex) {
      row[key] = previousValue;
    }
  });

  return data;
};

/**
 *
 * @param {string} from - date in ISO 8601, no fractional seconds
 * @param {string} to - date in ISO 8601, no fractional seconds
 * @param {string} key - data set key
 * @param {array} data - Single meter
 * @param {number} step - readings interval in seconds
 */
export const lineData = (from, to, key, data, step = 900) => {
  const valueOfFrom = moment(from).valueOf();
  const valueOfTo = moment(to).valueOf();

  return duplicateReadings(formatData(fillEmptySlots(step * 1000, valueOfFrom, valueOfTo, key, data)), key);
};
