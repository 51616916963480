import React from 'react';
import { Tooltip as ReTooltip } from 'recharts';

import { formatNumber, prefixNumber, printValue } from 'utils';

import styles from './Tooltip.module.scss';

const Legend = (props) => {
  const { payload } = props;

  const renderHtml = (html) => <span dangerouslySetInnerHTML={{ __html: html }}></span>;

  const valFormatter = (value, index) => {
    if (props.units) {
      if (Array.isArray(props.units)) {
        return printValue(value, props.units);
      }
      if (typeof props.units === 'object' && props.units[index]) {
        return printValue(value, props.units[index]);
      }
    } else if (props.unit) {
      return formatNumber(value);
    }

    return value;
  };

  return (
    <div className={styles.tooltip}>
      <ul className={styles.list}>
        {payload.map((entry, index) => (
          <li key={`item-${index}`} className={styles.value}>
            {props.includeName && renderHtml(entry.name)}
            {props.includeName && `: `}

            {props.prefixUnit ? (
              <>
                {typeof props.unit === 'string'
                  ? renderHtml(prefixNumber(entry.value, props.unit))
                  : renderHtml(prefixNumber(entry.value, props.unit[index]))}
              </>
            ) : (
              <>{valFormatter(entry.value, index)}</>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default class Tooltip extends ReTooltip {
  static defaultProps = {
    ...ReTooltip.defaultProps,
    content: Legend,
    includeName: true,
    unit: '',
  };
}
