export const FETCH_TRAFFIC = 'FETCH_TRAFFIC';
export const GOT_TRAFFIC = 'GOT_TRAFFIC';
export const APPEND_TRAFFIC = 'APPEND_TRAFFIC';
export const SET_TRAFFIC = 'SET_TRAFFIC';

export const fetchTraffic = () => ({
  type: FETCH_TRAFFIC,
});

export const gotTraffic = (data) => ({
  type: GOT_TRAFFIC,
  payload: data,
});

export const appendTraffic = (data) => ({
  type: APPEND_TRAFFIC,
  payload: data,
});

export const setTraffic = (data) => ({
  type: SET_TRAFFIC,
  payload: data,
});
