import { combineReducers } from 'redux';
import { apiReducer } from './api';
import { electricityReducer } from './electricity';
import { uiReducer } from './ui';
import { airReducer } from './air';
import { trafficReducer } from './traffic';
import { lightsReducer } from './lights';
import { metersReducer } from './meters';
import { cyclistsReducer } from './cyclists';
import { rodentsReducer } from './rodents';
import { gasReducer } from './gas';
import { packageReducer } from './package';
import { co2Reducer } from './co2';
import { waterReducer } from './water';

const rootReducer = combineReducers({
  ui: uiReducer,
  api: apiReducer,
  meters: metersReducer,
  electricity: electricityReducer,
  air: airReducer,
  traffic: trafficReducer,
  lights: lightsReducer,
  cyclists: cyclistsReducer,
  rodents: rodentsReducer,
  gas: gasReducer,
  package: packageReducer,
  co2: co2Reducer,
  water: waterReducer,
});

export default rootReducer;
