export const FETCH_CYCLISTS = 'FETCH_CYCLISTS';
export const GOT_CYCLISTS = 'GOT_CYCLISTS';
export const APPEND_CYCLISTS = 'APPEND_CYCLISTS';
export const SET_CYCLISTS = 'SET_CYCLISTS';

export const fetchCyclists = () => ({
  type: FETCH_CYCLISTS,
});

export const gotCyclists = (data) => ({
  type: GOT_CYCLISTS,
  payload: data,
});

export const appendCyclists = (data) => ({
  type: APPEND_CYCLISTS,
  payload: data,
});

export const setCyclists = (data) => ({
  type: SET_CYCLISTS,
  payload: data,
});
