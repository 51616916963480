import React from 'react';
import PropTypes from 'prop-types';

import styles from './Sidebar.module.scss';

const Sidebar = ({ children }) => (
  <div className={styles.sidebar}>
    <div className={styles.container}>{children}</div>
  </div>
);

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Sidebar;
