import { addNewMetersToState, returnLoadingState, replaceMeters } from '../utils';
import { FETCH_PACKAGES, APPEND_PACKAGES, SET_PACKAGES } from '../../actions';

export const initialState = {
  loading: false,
  meters: [],
};

export const packageReducer = (state = initialState, action = { type: undefined, payload: [] }) => {
  switch (action.type) {
    case FETCH_PACKAGES:
      return returnLoadingState(state);

    case APPEND_PACKAGES:
      return addNewMetersToState(state, action.payload);

    case SET_PACKAGES:
      return replaceMeters(state, action.payload);

    default:
      return state;
  }
};
