import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ResponsiveContainer, LineChart as Chart, Line, ReferenceLine } from 'recharts';

import { Loading } from 'components';
import { XAxisHours as XAxis, Legend, Tooltip, CustomLegend } from '..';
import styles from './LineChart.module.scss';
import { ticks } from '../XAxisHours/ticks';

const LineChart = (props) => {
  const colors = ['#FFD400', '#6D96C1'];

  const currentTime = props.currentTimestamp;
  const currentStep = currentTime - (currentTime % 900000);
  const currentReference = moment(currentStep).format('H:mm');
  const cNames = [styles.lineChart, props.hideAxis ? styles.hideAxis : '', props.hideLegend ? styles.hideLegend : ''];
  const bottomMargin = props.hideLegend ? 5 : 47;

  const render = () => {
    switch (true) {
      case props.loading:
        return <Loading />;

      case props.noData:
      // TODO: temporaraly removed for demo return <NoData />;

      // eslint-disable-next-line: no-fallthrough
      default:
        return (
          <Chart data={props.data} margin={{ top: 5, right: 5, bottom: bottomMargin, left: 5 }}>
            <XAxis dataKey="time" />

            {props.reference && (
              <ReferenceLine
                y={props.reference.point}
                strokeWidth={1.5}
                strokeDasharray="1.5 1.5"
                stroke={props.reference.color || '#e62323'}
              />
            )}

            {ticks.map((tick) => (
              <ReferenceLine key={tick} x={tick} strokeWidth={1.5} strokeDasharray="5, 5" />
            ))}
            <Tooltip unit={props.unit} units={props.units} prefixUnit={props.prefixUnit} />
            <ReferenceLine x={currentReference} stroke="#E62323" />
            {!props.hideLegend && (
              <Legend
                verticalAlign="middle"
                content={
                  <CustomLegend {...props} color={props.reference && props.reference.color}>
                    {props.reference && props.reference.label}
                  </CustomLegend>
                }
              />
            )}

            {props.lines.map((settings, index) => (
              <Line
                key={settings.key}
                dataKey={settings.key}
                name={settings.label}
                stroke={settings.color || colors[index]}
                type="monotone"
                strokeWidth={4}
                dot={false}
              />
            ))}
          </Chart>
        );
    }
  };

  return (
    <div className={cNames.join(' ')}>
      <ResponsiveContainer height={props.height || 260}>{render()}</ResponsiveContainer>
    </div>
  );
};

LineChart.propTypes = {
  currentTimestamp: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  lines: PropTypes.array.isRequired,
  data: PropTypes.array,
  hideLegend: PropTypes.bool,
  hideAxis: PropTypes.bool,
  height: PropTypes.number,
  unit: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  units: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  prefixUnit: PropTypes.bool,
  noData: PropTypes.bool,
};

LineChart.defaultProps = {
  prefixUnit: false,
  noData: false,
};

export default LineChart;
export * from './utility';
