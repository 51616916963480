import { addNewMetersToState, returnLoadingState, replaceMeters } from '../utils';
import { FETCH_LIGHTS, APPEND_LIGHTS, SET_LIGHTS } from '../../actions';

export const initialState = {
  loading: false,
  meters: [],
};

export const lightsReducer = (state = initialState, action = { type: undefined, payload: [] }) => {
  switch (action.type) {
    case FETCH_LIGHTS:
      return returnLoadingState(state);

    case APPEND_LIGHTS:
      return addNewMetersToState(state, action.payload);

    case SET_LIGHTS:
      return replaceMeters(state, action.payload);

    default:
      return state;
  }
};
