import { addNewMetersToState, returnLoadingState, replaceMeters } from '../utils';
import { FETCH_WATER, APPEND_WATER, SET_WATER } from '../../actions';

export const initialState = {
  loading: false,
  meters: [],
};

export const waterReducer = (state = initialState, action = { type: undefined, payload: [] }) => {
  switch (action.type) {
    case FETCH_WATER:
      return returnLoadingState(state);

    case APPEND_WATER:
      return addNewMetersToState(state, action.payload);

    case SET_WATER:
      return replaceMeters(state, action.payload);

    default:
      return state;
  }
};
