import moment from 'moment';
import { timelineState, SET_CURRENT_TIME, SET_TIMELINE, TOGGLE_ABOUT_BOX } from '../../actions';

const currentTime = moment();
const startOfToday = currentTime.clone().startOf('day');
const startOfWeek = currentTime.clone().startOf('isoWeek');

export const initialState = {
  aboutBox: false,
  currentTime: currentTime.format(),
  yesterday: {
    start: startOfToday.clone().subtract(1, 'days').format(),
    end: startOfToday.format(),
  },
  today: {
    start: startOfToday.format(),
    end: startOfToday.clone().add(1, 'days').format(),
  },
  week: {
    start: startOfWeek.format(),
    end: startOfWeek.clone().add(1, 'week').format(),
  },
  monday: {
    start: startOfWeek.format(),
    end: startOfWeek.clone().add(1, 'days').format(),
  },
  tuesday: {
    start: startOfWeek.clone().add(1, 'days').format(),
    end: startOfWeek.clone().add(1, 'days').add(1, 'days').format(),
  },
  wednesday: {
    start: startOfWeek.clone().add(2, 'days').format(),
    end: startOfWeek.clone().add(2, 'days').add(1, 'days').format(),
  },
  thursday: {
    start: startOfWeek.clone().add(3, 'days').format(),
    end: startOfWeek.clone().add(3, 'days').add(1, 'days').format(),
  },
  friday: {
    start: startOfWeek.clone().add(4, 'days').format(),
    end: startOfWeek.clone().add(4, 'days').add(1, 'days').format(),
  },
  saturday: {
    start: startOfWeek.clone().add(5, 'days').format(),
    end: startOfWeek.clone().add(5, 'days').add(1, 'days').format(),
  },
  sunday: {
    start: startOfWeek.clone().add(6, 'days').format(),
    end: startOfWeek.clone().add(6, 'days').add(1, 'days').format(),
  },
  last7days: {
    start: startOfToday.clone().subtract(1, 'weeks').format(),
    end: startOfToday.clone().add(1, 'days').format(),
  },
  timeline: timelineState(currentTime.format()),
};

export const uiReducer = (state = initialState, action = { type: undefined, payload: '' }) => {
  switch (action.type) {
    case SET_CURRENT_TIME:
      return {
        ...state,
        currentTime: action.payload,
      };

    case SET_TIMELINE:
      return {
        ...state,
        timeline: action.payload,
      };

    case TOGGLE_ABOUT_BOX:
      return {
        ...state,
        aboutBox: !state.aboutBox,
      };

    default:
      return state;
  }
};
