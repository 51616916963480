import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/sl';

import { setTimeline } from 'redux/actions';
import { DatePicker, TimePicker } from 'components';

import styles from './Timeline.module.scss';

const Timeline = (props) => {
  const step = props.step || 900;
  const start = moment(props.currentTime);
  const [dateStamp, setDateStamp] = useState(start.clone().startOf('day').valueOf());
  const [hourStamp, setHourStamp] = useState(start.clone().valueOf() % 86400000);

  const handleChange = (day, milliseconds) => {
    const t = moment(day).startOf('day').add(milliseconds, 'ms').format();

    props.setTimeline(t.valueOf());
  };

  const onDateChange = (dayStartTimestamp) => {
    setDateStamp(dayStartTimestamp);
    handleChange(dayStartTimestamp, hourStamp);
  };

  const onTimeChange = (secondTimestamp) => {
    setHourStamp(secondTimestamp);
    handleChange(dateStamp, secondTimestamp);
  };

  const backToCurrentTime = () => {
    const c = moment().valueOf();
    const s = c % (86400 * 1000);
    const h = s - (s % (step * 1000)) + moment().utcOffset() * 60 * 1000;
    const d = c - (c % (86400 * 1000));

    setDateStamp(d);
    setHourStamp(h);
    handleChange(d, h);
  };

  const backCNames = [styles.back, props.showBackToNow ? styles.show : styles.hide];

  return (
    <div className={styles.container}>
      <div className={styles.datePicker}>
        <div className={backCNames.join(' ')} onClick={backToCurrentTime}>
          Nazaj do današnjega dne
        </div>
        <DatePicker onChange={onDateChange} start={props.currentTime} />
      </div>
      <TimePicker onChange={onTimeChange} start={props.currentTime} step={step} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentTime: state.ui.timeline.selected,
  showBackToNow: moment(state.ui.timeline.day.start).format() !== moment(state.ui.today.start).format(),
});

const mapDispatchToProps = (dispatch) => ({
  setTimeline: (timestamp) => {
    dispatch(setTimeline(timestamp));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Timeline);
