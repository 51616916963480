/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { selectCurrentData, selectLineAvgData, selectDayAverageAverage } from 'redux/selectors';
import { getEnvironment2Data } from 'redux/services';

import { dataRange, emptyData, printValue, formatNumber, formatSelectedInterval, formatWindDirection } from 'utils';
import * as units from 'utils/units';
import { combineLines } from 'components/charts/LineChart';
import { Page, Sidebar, Main, Map, BackToHomeLink, LineChart, InfoBubble, PinAir } from 'components';

import styles from './Air.module.scss';

const noiseLineSettings = [
  { key: 'noise', label: 'Hrup', color: '#3B6590' },
  { key: 'noisef', label: 'Hrup', color: '#BACADA' },
];

const particleLineSettings = [
  { key: 'pm010', label: 'pm<sub>1</sub>', color: '#FFD400' },
  { key: 'pm025', label: 'pm<sub>2,5</sub>', color: '#3B6590' },
  { key: 'pm100', label: 'pm<sub>10</sub>', color: '#2B9974' },
  { key: 'pm010f', label: 'pm<sub>1</sub>', color: '#FFF2B3' },
  { key: 'pm025f', label: 'pm<sub>2,5</sub>', color: '#BACADA' },
  { key: 'pm100f', label: 'pm<sub>10</sub>', color: '#B5D9D2' },
];
const temperatureLineSettings = [
  { key: 'temperature', label: 'Temperatura', color: '#FFD400' },
  { key: 'humidity', label: 'Vlažnost', color: '#3B6590' },
  { key: 'temperaturef', label: 'Temperatura', color: '#FFF2B3' },
  { key: 'humidityf', label: 'Vlažnost', color: '#BACADA' },
];

const Air = (props) => {
  const {
    pm10Today,
    pm25Today,
    pm100Today,
    pm10Future,
    pm25Future,
    pm100Future,
    noiseToday,
    noiseFuture,
    tempLineToday,
    humiLineToday,
    tempLineFuture,
    humiLineFuture,
    dataInterval,
    selectedTime,
  } = props;
  const timestamp = moment(selectedTime).valueOf();

  const [particleData, setParticleData] = useState([]);
  const [temperatureData, setTemperatureData] = useState([]);
  const [noiseData, setNoiseData] = useState([]);

  useEffect(() => {
    props.getEnvironment2Data(dataInterval.start, dataInterval.end);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInterval.start, dataInterval.end]);

  useEffect(() => {
    setParticleData(combineLines(pm10Today, pm25Today, pm100Today, pm10Future, pm25Future, pm100Future));
    setTemperatureData(combineLines(tempLineToday, humiLineToday, tempLineFuture, humiLineFuture));
    setNoiseData(combineLines(noiseToday, noiseFuture));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pm25Today, pm100Today, pm25Future, pm100Future, tempLineToday, humiLineToday, tempLineFuture, humiLineFuture]);

  return (
    <Page>
      <Sidebar>
        <BackToHomeLink />
        <h1>Zrak</h1>
        <p>Izmera zračnih vrednosti v merilnem intervalu 1h.</p>
        <h2>trenutna temperatura</h2>
        <span className="text-large" style={{ color: temperatureLineSettings[0].color }}>
          {printValue(props.selectedAirTemperature, units.temperature)}
        </span>
        <h2>trenutna vlažnost</h2>
        <span className="text-large" style={{ color: temperatureLineSettings[1].color }}>
          {printValue(props.selectedAirHumidity, units.humidity)}
        </span>
        <div className={styles.removeLastLegend2}>
          <h2>Dnevni trend temperature in vlažnosti</h2>
          <LineChart
            data={temperatureData}
            lines={temperatureLineSettings}
            currentTimestamp={timestamp}
            loading={props.inProgress}
            reference={{ point: props.humidityTodayAvg, label: 'Povprečna vlažnost' }}
            units={{
              0: units.temperature,
              1: units.humidity,
            }}
            noData={emptyData(temperatureData, temperatureLineSettings[0].key, props.selectedDate)}
          />
        </div>
        <h2>
          Dnevno povprečje PM<sub>1</sub>
        </h2>
        <span className="text-large" style={{ color: particleLineSettings[0].color }}>
          {printValue(props.pm10TodayAvg, units.air)}
        </span>
        <h2>
          Dnevno povprečje PM<sub>2,5</sub>
        </h2>
        <span className="text-large" style={{ color: particleLineSettings[1].color }}>
          {printValue(props.pm25TodayAvg, units.air)}
        </span>
        <h2>
          Dnevno povprečje PM<sub>10</sub>
        </h2>
        <span className="text-large" style={{ color: particleLineSettings[2].color }}>
          {printValue(props.pm100TodayAvg, units.air)}
        </span>
        <div className={styles.removeLastLegend3}>
          <h2>Dnevni trend PM delcev</h2>
          <LineChart
            data={particleData}
            lines={particleLineSettings}
            currentTimestamp={timestamp}
            loading={props.inProgress}
            reference={{ point: 25, label: 'meja pm25' }}
            units={units.air}
            noData={emptyData(particleData, particleLineSettings[0].key, props.selectedDate)}
          />
        </div>

        <h2>Povprečna raven hrupa</h2>
        <span className="text-large" style={{ color: noiseLineSettings[0].color }}>
          {printValue(props.noiseTodayAvg, units.noise)}
        </span>

        <div className={styles.removeLastLegend1}>
          <h2>Dnevni trend hrupa</h2>
          <LineChart
            data={noiseData}
            lines={noiseLineSettings}
            currentTimestamp={timestamp}
            loading={props.inProgress}
            reference={{ point: 60, label: 'pogovor' }}
            units={units.noise}
            noData={emptyData(noiseData, noiseLineSettings[0].key, props.selectedDate)}
          />
        </div>
      </Sidebar>
      <Main>
        <Map step={3600}>
          {props.selectedData.map((meter) => {
            const no2 = meter.readings.length ? meter.readings[0].concentrationOfNO2 : 0;
            const o3 = meter.readings.length ? meter.readings[0].concentrationOfO3 : 0;
            const pm010 = meter.readings.length ? meter.readings[0].concentrationOfPM010 : 0;
            const pm025 = meter.readings.length ? meter.readings[0].concentrationOfPM025 : 0;
            const pm100 = meter.readings.length ? meter.readings[0].concentrationOfPM100 : 0;
            const h = meter.readings.length ? meter.readings[0].relativeAirHumidity : 0;
            const t = meter.readings.length ? meter.readings[0].airTemperature : 0;
            const windDirection = meter.readings.length ? meter.readings[0].windDirection : 0;
            const windSpeed = meter.readings.length ? meter.readings[0].windSpeed : 0;
            const noise = meter.readings.length ? meter.readings[0].noise : 0;
            const cName = ['readings', props.isSelectedPrediction ? 'predictionData' : 'realData'];

            return (
              <InfoBubble
                key={meter.meter}
                lng={meter.location.longitude}
                lat={meter.location.latitude}
                zoom={1}
                icon={<PinAir />}
                hover
              >
                <h3>
                  Zrak <span>{formatSelectedInterval(timestamp)}</span>
                </h3>
                <div className={cName.join(' ')}>
                  <span className="label">
                    NO<sub>2</sub>:
                  </span>
                  <span className="value">{formatNumber(no2)}</span>

                  <span className="label">
                    O<sub>3</sub>:
                  </span>
                  <span className="value">{formatNumber(o3)}</span>

                  <span className="label">
                    PM<sub>1</sub>:
                  </span>
                  <span className="value">{printValue(pm010, units.air)}</span>

                  <span className="label">
                    PM<sub>2,5</sub>:
                  </span>
                  <span className="value">{printValue(pm025, units.air)}</span>

                  <span className="label">
                    PM<sub>10</sub>:
                  </span>
                  <span className="value">{printValue(pm100, units.air)}</span>

                  <span className="label">Relativna vlažnost:</span>
                  <span className="value">{printValue(h, units.humidity)}</span>

                  <span className="label">Temperatura zraka:</span>
                  <span className="value">{printValue(t, units.temperature)}</span>

                  <span className="label">Hrup:</span>
                  <span className="value">{printValue(noise, units.noise)}</span>

                  <span className="label">Hitrost vetra:</span>
                  <span className="value">{printValue(windSpeed, units.speed)}</span>

                  <span className="label">Smer vetra:</span>
                  <span className="value">{formatWindDirection(windDirection)}</span>
                </div>
              </InfoBubble>
            );
          })}
        </Map>
      </Main>
    </Page>
  );
};

const selectedValue = (selectedMeters, key) => {
  if (!selectedMeters.length) return 0;
  if (!selectedMeters[0].readings.length) return 0;

  return selectedMeters[0].readings[0][key];
};

const mapStateToProps = (state) => {
  const { hour, selected } = state.ui.timeline;
  const dataInterval = dataRange(state.ui.timeline, selected);

  const [isSelectedPrediction, redoutHourData] = selectCurrentData(state, 'air', undefined, undefined, 3600);

  const [noiseToday, noiseFuture] = selectLineAvgData(state, 'air', 'noise', 'noise');
  const [pm10Today, pm10Future] = selectLineAvgData(state, 'air', 'concentrationOfPM010', 'pm010');
  const [pm25Today, pm25Future] = selectLineAvgData(state, 'air', 'concentrationOfPM025', 'pm025');
  const [pm100Today, pm100Future] = selectLineAvgData(state, 'air', 'concentrationOfPM100', 'pm100');

  const [tempLineToday, tempLineFuture] = selectLineAvgData(state, 'air', 'airTemperature', 'temperature');
  const [humiLineToday, humiLineFuture] = selectLineAvgData(state, 'air', 'relativeAirHumidity', 'humidity');

  return {
    currentTime: state.ui.currentTime,
    selectedDate: state.ui.timeline.selected,
    timeline: state.ui.timeline,
    inProgress: state.api.pending.length > 0,
    dataInterval,

    humidityTodayAvg: selectDayAverageAverage(state, 'air', 'relativeAirHumidity'),
    pm10TodayAvg: selectDayAverageAverage(state, 'air', 'concentrationOfPM010'),
    pm25TodayAvg: selectDayAverageAverage(state, 'air', 'concentrationOfPM025'),
    pm100TodayAvg: selectDayAverageAverage(state, 'air', 'concentrationOfPM100'),
    noiseTodayAvg: selectDayAverageAverage(state, 'air', 'noise'),
    pm10Today,
    pm10Future,
    pm25Today,
    pm25Future,
    pm100Today,
    pm100Future,

    noiseToday,
    noiseFuture,

    tempLineToday,
    tempLineFuture,
    humiLineToday,
    humiLineFuture,

    selectedTime: hour.start,
    selectedData: redoutHourData,
    isSelectedPrediction,
    selectedAirTemperature: selectedValue(redoutHourData, 'airTemperature'),
    selectedAirHumidity: selectedValue(redoutHourData, 'relativeAirHumidity'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEnvironment2Data: getEnvironment2Data(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Air);
