import { GOT_TRAFFIC, setTraffic, setMeters } from '../../actions';

export const trafficMdl = ({ dispatch }) => (next) => (action) => {
  next(action);

  const makeMeterId = (location) => `meter-at-${location.latitude}-${location.longitude}`;

  if (action.type === GOT_TRAFFIC) {
    /*
     * Transform data to common format
     */
    const normalizedMeters = action.payload.sensors.map((sensor) => ({
      ...sensor,
      meter: makeMeterId(sensor.location),
      interval: undefined,
    }));

    const metersData = normalizedMeters.map((meter) => ({
      meter: meter.meter,
      location: meter.location,
      type: 'traffic',
    }));

    dispatch(setTraffic(normalizedMeters));
    dispatch(setMeters(metersData));
  }
};
