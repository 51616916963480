import React from 'react';

export function formatNumber(number, decimals = 2) {
  return Number.parseFloat(Number.parseFloat(number).toFixed(decimals)).toLocaleString('sl-SI');
}

export function prefixNumber(number, unit, onlyUnit = false) {
  const prefix = ['', 'k', 'M', 'G'];

  const formatter = (n, [firstPrefix, ...prefixes]) => {
    if (n < 1000 || prefixes.length === 0) {
      const f = formatNumber(n);

      if (onlyUnit) {
        return `${firstPrefix}${unit}`;
      }

      return `${f} ${firstPrefix}${unit}`;
    }

    return formatter(n / 1000, prefixes);
  };

  return formatter(number, prefix);
}

export function formatWindDirection(number) {
  if (typeof number !== 'number') return '/';

  const units = ['Server', 'Server/Vzhod', 'Vzhod', 'Jug/Vzhod', 'Jug', 'Jug/Zahod', 'Zahod', 'Sever/Zahod'];

  const index = Math.round(number / 45);

  return units[index];
}

export function formatValue(number, units) {
  const formatter = (num, [firstUnit, ...nextUnits]) => {
    const { unit, divider = 1 } = firstUnit;

    if (num / divider < 1 && nextUnits.length > 0) {
      return formatter(num, nextUnits);
    }

    const f = formatNumber(num / divider);

    return [f, unit];
  };

  return formatter(number, units);
}

export function printValue(number, units) {
  if (typeof number !== 'number') return '/';

  const [value, unit] = formatValue(number, units);

  return <span dangerouslySetInnerHTML={{ __html: `${value}${unit}` }}></span>;
}
