import React from 'react';
import { Link } from 'react-router-dom';
import { Logo3fsText, Help, AboutBox } from 'components';

import styles from './Header.module.scss';

const Header = (props) => (
  <>
    <AboutBox visible={props.aboutBox} toggle={props.toggleAboutBox} />

    <header className={styles.header}>
      <a href="https://3fs.si/" target="_blank" rel="noopener noreferrer" className={styles.trifs}>
        <Logo3fsText />
      </a>
      <Link to="/" className={styles.title}>
        <span>Pametna Mlaka</span>
      </Link>
      <Help className={styles.help} onClick={props.toggleAboutBox} />
    </header>
  </>
);

export default Header;
