import React from 'react';
import { Legend as ReLegend } from 'recharts';

import styles from './Legend.module.scss';

export const CustomLegend = (props) => {
  const { payload } = props;

  const renderHtml = (html) => <span dangerouslySetInnerHTML={{ __html: html }}></span>;

  return (
    <ul className={styles.legend}>
      {payload.map((entry, index) => (
        <li key={`item-${index}`} className={styles.value}>
          <span className={styles.icon} style={{ background: entry.color }}></span>
          {renderHtml(entry.value)}
        </li>
      ))}
      {props.children && (
        <li className={styles.value}>
          <span className={styles.reference}></span>
          {props.children}
        </li>
      )}
    </ul>
  );
};

export default class Legend extends ReLegend {
  static defaultProps = {
    ...ReLegend.defaultProps,
    content: CustomLegend,
  };
}

export const ReferenceLegend = (props) => (
  <ul className={styles.legend}>
    <li className={styles.value}>
      <span className={styles.reference} style={{ background: props.color }}></span>
      {props.children}
    </li>
  </ul>
);
