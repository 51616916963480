import { addNewMetersToState, returnLoadingState, replaceMeters } from '../utils';
import { FETCH_ELECTRICITY, APPEND_ELECTRICITY, SET_ELECTRICITY } from '../../actions';

export const initialState = {
  loading: false,
  meters: [],
};

export const electricityReducer = (state = initialState, action = { type: undefined, payload: [] }) => {
  switch (action.type) {
    case FETCH_ELECTRICITY:
      return returnLoadingState(state);

    case APPEND_ELECTRICITY:
      return addNewMetersToState(state, action.payload);

    case SET_ELECTRICITY:
      return replaceMeters(state, action.payload);

    default:
      return state;
  }
};
