import moment from 'moment';
export const SET_CURRENT_TIME = 'SET_CURRENT_TIME';
export const SET_TIMELINE = 'SET_TIMELINE';
export const TOGGLE_ABOUT_BOX = 'TOGGLE_ABOUT_BOX';

export const timelineState = (timestamp) => {
  const currentTime = moment(timestamp);
  const startOfHour = currentTime.clone().startOf('hour');
  const startOfDay = currentTime.clone().startOf('day');
  const startOfWeek = currentTime.clone().startOf('isoWeek');

  let quarterHourStart = currentTime.clone().unix();

  quarterHourStart -= quarterHourStart % 900;

  return {
    selected: moment(timestamp).format(),
    dayIndex: currentTime.clone().isoWeekday() - 1,
    quarterHour: {
      start: moment(quarterHourStart * 1000).format(),
      end: moment(quarterHourStart * 1000)
        .add(15, 'minutes')
        .format(),
    },
    hour: {
      start: startOfHour.format(),
      end: startOfHour.clone().add(1, 'hour').format(),
    },
    previousDay: {
      start: startOfDay.clone().subtract(1, 'days').format(),
      end: startOfDay.clone().format(),
    },
    day: {
      start: startOfDay.format(),
      end: startOfDay.clone().add(1, 'days').format(),
    },
    week: {
      start: startOfWeek.format(),
      end: startOfWeek.clone().add(1, 'week').format(),
    },
    previousWeek: {
      start: startOfWeek.clone().subtract(1, 'week').format(),
      end: startOfWeek.format(),
    },
    monday: {
      start: startOfWeek.format(),
      end: startOfWeek.clone().add(1, 'days').format(),
    },
    tuesday: {
      start: startOfWeek.clone().add(1, 'days').format(),
      end: startOfWeek.clone().add(1, 'days').add(1, 'days').format(),
    },
    wednesday: {
      start: startOfWeek.clone().add(2, 'days').format(),
      end: startOfWeek.clone().add(2, 'days').add(1, 'days').format(),
    },
    thursday: {
      start: startOfWeek.clone().add(3, 'days').format(),
      end: startOfWeek.clone().add(3, 'days').add(1, 'days').format(),
    },
    friday: {
      start: startOfWeek.clone().add(4, 'days').format(),
      end: startOfWeek.clone().add(4, 'days').add(1, 'days').format(),
    },
    saturday: {
      start: startOfWeek.clone().add(5, 'days').format(),
      end: startOfWeek.clone().add(5, 'days').add(1, 'days').format(),
    },
    sunday: {
      start: startOfWeek.clone().add(6, 'days').format(),
      end: startOfWeek.clone().add(6, 'days').add(1, 'days').format(),
    },
    last2days: {
      start: startOfDay.clone().subtract(2, 'days').format(),
      end: startOfDay.clone().add(1, 'days').format(),
    },
    last8days: {
      start: startOfDay.clone().subtract(8, 'days').format(),
      end: startOfDay.clone().add(1, 'days').add(1, 'seconds').format(),
    },
  };
};

export const setCurrentTime = (timestamp) => ({
  type: SET_CURRENT_TIME,
  payload: moment(timestamp).format(),
});

export const setTimeline = (timestamp) => ({
  type: SET_TIMELINE,
  payload: timelineState(timestamp),
});

export const toggleAboutBox = () => ({
  type: TOGGLE_ABOUT_BOX,
});
