/* eslint-disable implicit-arrow-linebreak, function-paren-newline, max-params */
import moment from 'moment';
import { createCachedSelector } from 're-reselect';

import {
  lastTimestamp,
  predictFutureData,
  selectAverage,
  selectMax,
  selectSum,
  selectSumByTime,
  selectAvgByTime,
  selectByDate as byDate,
  selectBetween as between,
  selectGraphData as graphData,
  selectLightsTotal as selectTotal,
} from 'redux/selectors';
import { lineData, combineLines } from 'components/charts/LineChart';
import { selectedData } from 'utils';

const weekParser = (meters, times, dataKey) => {
  const sum = selectSumByTime(meters, dataKey);

  const weekData = [
    byDate(sum, times.monday.start, false),
    byDate(sum, times.tuesday.start, false),
    byDate(sum, times.wednesday.start, false),
    byDate(sum, times.thursday.start, false),
    byDate(sum, times.friday.start, false),
    byDate(sum, times.saturday.start, false),
    byDate(sum, times.sunday.start, false),
  ];

  const weekPrediction = [
    byDate(predictFutureData(weekData[0], sum, times.monday.start, 3600), times.monday.start, false),
    byDate(predictFutureData(weekData[1], sum, times.tuesday.start, 3600), times.tuesday.start, false),
    byDate(predictFutureData(weekData[2], sum, times.wednesday.start, 3600), times.wednesday.start, false),
    byDate(predictFutureData(weekData[3], sum, times.thursday.start, 3600), times.thursday.start, false),
    byDate(predictFutureData(weekData[4], sum, times.friday.start, 3600), times.friday.start, false),
    byDate(predictFutureData(weekData[5], sum, times.saturday.start, 3600), times.saturday.start, false),
    byDate(predictFutureData(weekData[6], sum, times.sunday.start, 3600), times.sunday.start, false),
  ];

  const weekBars = [
    { day: 'pon', value: selectSum(weekData[0], dataKey), future: 0 },
    { day: 'tor', value: selectSum(weekData[1], dataKey), future: 0 },
    { day: 'sre', value: selectSum(weekData[2], dataKey), future: 0 },
    { day: 'čet', value: selectSum(weekData[3], dataKey), future: 0 },
    { day: 'pet', value: selectSum(weekData[4], dataKey), future: 0 },
    { day: 'sob', value: selectSum(weekData[5], dataKey), future: 0 },
    { day: 'ned', value: selectSum(weekData[6], dataKey), future: 0 },
  ];

  weekBars[0].future = selectSum(weekPrediction[0], dataKey) - weekBars[0].value;
  weekBars[1].future = selectSum(weekPrediction[1], dataKey) - weekBars[1].value;
  weekBars[2].future = selectSum(weekPrediction[2], dataKey) - weekBars[2].value;
  weekBars[3].future = selectSum(weekPrediction[3], dataKey) - weekBars[3].value;
  weekBars[4].future = selectSum(weekPrediction[4], dataKey) - weekBars[4].value;
  weekBars[5].future = selectSum(weekPrediction[5], dataKey) - weekBars[5].value;
  weekBars[6].future = selectSum(weekPrediction[6], dataKey) - weekBars[6].value;

  return weekBars;
};
const line = (range, k, name, data, interval = 3600) =>
  lineData(range.start, range.end, name, graphData(data, k, name)[0], interval);

const getMeters = (state, type) => state[type].meters;
const getTimeline = (state, type) => state.ui.timeline;
const getKey = (state, type, key) => key;
const getName = (state, type, key, name) => name;
const getInterval = (state, type, key, name, interval = 3600) => interval;
const getToday = (state) => state.ui.today;
const getCurrentTime = (state) => state.ui.currentTime;
const getUi = (state) => state.ui;

export const selectWeekData = createCachedSelector(getMeters, getTimeline, getKey, (meters, timeline, dataKey) =>
  weekParser(meters, timeline, dataKey),
)((state, type, key) => `${type}-${key}`);

export const selectLineData = createCachedSelector(
  getMeters,
  getTimeline,
  getKey,
  getName,
  getInterval,
  (a, b, c, d, f, dayBefore) => dayBefore,
  (meters, timeline, key, name, interval, dayBefore = false) => {
    const { day, previousDay } = timeline;
    const forDay = dayBefore ? previousDay : day;

    const lastKnownValue = lastTimestamp(meters);
    const sum = selectSumByTime(meters);

    const redoutDay = byDate(sum, forDay.start);
    const redoutDayPredictionData = predictFutureData(redoutDay, sum, forDay.start, interval);
    const redoutDayPrediction = between(redoutDayPredictionData, moment(lastKnownValue), moment(forDay.end));

    const data = line(forDay, key, name, redoutDay, interval);
    const prediction = line(forDay, key, `${name}f`, redoutDayPrediction, interval);

    return [data, prediction];
  },
)((state, type, key, interval) => `${type}-${key}`);

export const selectLineAvgData = createCachedSelector(
  getMeters,
  getTimeline,
  getKey,
  getName,
  getInterval,
  (a, b, c, d, f, dayBefore) => dayBefore,
  (meters, timeline, key, name, interval, dayBefore = false) => {
    const { day, previousDay } = timeline;
    const forDay = dayBefore ? previousDay : day;

    const lastKnownValue = lastTimestamp(meters);
    const avg = selectAvgByTime(meters);

    const redoutDay = byDate(avg, forDay.start);
    const redoutDayPredictionData = predictFutureData(redoutDay, avg, forDay.start, interval);
    const redoutDayPrediction = between(redoutDayPredictionData, moment(lastKnownValue), moment(forDay.end));

    const data = line(forDay, key, name, redoutDay, interval);
    const prediction = line(forDay, key, `${name}f`, redoutDayPrediction, interval);

    return [data, prediction];
  },
)((state, type, key, interval) => `${type}-${key}`);

export const selectTodayLineData = createCachedSelector(
  getMeters,
  getUi,
  getKey,
  getName,
  getInterval,
  (meters, ui, key, name, interval) => {
    const { today } = ui;
    const lastKnownValue = lastTimestamp(meters);
    const sum = selectSumByTime(meters);

    const redoutDay = byDate(sum, today.start);
    const redoutDayPredictionData = predictFutureData(redoutDay, sum, today.start, interval);
    const redoutDayPrediction = between(redoutDayPredictionData, moment(lastKnownValue), moment(today.end));

    const data = line(today, key, name, redoutDay, interval);
    const prediction = line(today, key, `${name}f`, redoutDayPrediction, interval);

    return combineLines(data, prediction);
  },
)((state, type, key, interval) => `${type}-${key}`);

export const selectTodayAvgLineData = createCachedSelector(
  getMeters,
  getUi,
  getKey,
  getName,
  getInterval,
  (meters, ui, key, name, interval) => {
    const { today } = ui;
    const lastKnownValue = lastTimestamp(meters);
    const avg = selectAvgByTime(meters);

    const redoutDay = byDate(avg, today.start);
    const redoutDayPredictionData = predictFutureData(redoutDay, avg, today.start, interval);
    const redoutDayPrediction = between(redoutDayPredictionData, moment(lastKnownValue), moment(today.end));

    const data = line(today, key, name, redoutDay, interval);
    const prediction = line(today, key, `${name}f`, redoutDayPrediction, interval);

    return combineLines(data, prediction);
  },
)((state, type, key, interval) => `${type}-${key}`);

export const selectTodayTotal = createCachedSelector(
  getMeters,
  getKey,
  getToday,
  getCurrentTime,

  (meters, key, today, currentTime) => {
    const redoutSum = selectSumByTime(meters, key);
    const redoutToday = byDate(redoutSum, today.start);
    const redoutTodayPredictionData = predictFutureData(redoutToday, redoutSum, today.start, 3600);
    const redoutToThisTime = between(redoutTodayPredictionData, today.start, currentTime);

    return selectTotal(redoutToThisTime, key);
  },
)((state, type, key) => `${type}-${key}`);

export const selectTodayAverage = createCachedSelector(
  getMeters,
  getKey,
  getToday,
  getCurrentTime,

  (meters, key, today, currentTime) => {
    const redoutSum = selectSumByTime(meters, key);
    const redoutToday = byDate(redoutSum, today.start);
    const redoutTodayPredictionData = predictFutureData(redoutToday, redoutSum, today.start, 3600);
    const redoutToThisTime = between(redoutTodayPredictionData, today.start, today.end);

    return selectAverage(redoutToThisTime, key);
  },
)((state, type, key) => `${type}-${key}`);

export const selectTodayAverageAverage = createCachedSelector(
  getMeters,
  getKey,
  getToday,
  getCurrentTime,

  (meters, key, today, currentTime) => {
    const redoutAvg = selectAvgByTime(meters, key);
    const redoutToday = byDate(redoutAvg, today.start);
    const redoutTodayPredictionData = predictFutureData(redoutToday, redoutAvg, today.start, 3600);
    const redoutToThisTime = between(redoutTodayPredictionData, today.start, today.end);

    return selectAverage(redoutToThisTime, key);
  },
)((state, type, key) => `${type}-${key}`);

export const selectDayAverage = createCachedSelector(
  getMeters,
  getKey,
  getTimeline,
  getCurrentTime,

  (meters, key, timeline, currentTime) => {
    const { day } = timeline;
    const redoutSum = selectSumByTime(meters, key);
    const redoutToday = byDate(redoutSum, day.start);
    const redoutTodayPredictionData = predictFutureData(redoutToday, redoutSum, day.start, 3600);
    const redoutToThisTime = between(redoutTodayPredictionData, day.start, day.end);

    return selectAverage(redoutToThisTime, key);
  },
)((state, type, key) => `${type}-${key}`);

export const selectDayAverageAverage = createCachedSelector(
  getMeters,
  getKey,
  getTimeline,
  getCurrentTime,

  (meters, key, timeline, currentTime) => {
    const { day } = timeline;
    const redoutAvg = selectAvgByTime(meters, key);
    const redoutToday = byDate(redoutAvg, day.start);
    const redoutTodayPredictionData = predictFutureData(redoutToday, redoutAvg, day.start, 3600);
    const redoutToThisTime = between(redoutTodayPredictionData, day.start, day.end);

    return selectAverage(redoutToThisTime, key);
  },
)((state, type, key) => `${type}-${key}`);

export const selectDayMax = createCachedSelector(
  getMeters,
  getKey,
  getTimeline,
  getCurrentTime,

  (meters, key, timeline, currentTime) => {
    const { day } = timeline;
    const redoutSum = selectSumByTime(meters, key);
    const redoutToday = byDate(redoutSum, day.start);
    const redoutTodayPredictionData = predictFutureData(redoutToday, redoutSum, day.start, 3600);
    const redoutToThisTime = between(redoutTodayPredictionData, day.start, day.end);

    return selectMax(redoutToThisTime, key);
  },
)((state, type, key) => `${type}-${key}`);

export const selectCurrentData = createCachedSelector(
  getMeters,
  getTimeline,
  getInterval,

  (meters, timeline, interval = 3600) => {
    const { day, hour, quarterHour } = timeline;
    const currentInterval = interval === 3600 ? hour : quarterHour;

    const redoutSelected = byDate(meters, day.start);
    const redoutSelectedPredictionData = predictFutureData(redoutSelected, meters, currentInterval.start, interval);

    return selectedData(redoutSelected, redoutSelectedPredictionData, currentInterval.start, 3600, true);
  },
)((state, type, key) => `${type}-${key}`);
