export const API_REQUEST = 'API_REQUEST';
export const API_REQUEST_STARTED = 'API_REQUEST_STARTED';
export const API_REQUEST_FINISH = 'API_REQUEST_FINISH';
export const API_REQUEST_ERROR = 'API_REQUEST_ERROR';
export const API_REQUEST_ERROR_REMOVE = 'API_REQUEST_ERROR_REMOVE';

const emptyFn = () => ({ type: '' });

export const apiRequest = (data, init = emptyFn, onLoad = emptyFn) => ({
  type: API_REQUEST,
  payload: data,
  callbacks: {
    init,
    onLoad,
  },
});

export const apiStart = (signature) => ({
  type: API_REQUEST_STARTED,
  payload: signature,
});

export const apiFinish = (signature) => ({
  type: API_REQUEST_FINISH,
  payload: signature,
});

export const apiError = (error, action) => ({
  type: API_REQUEST_ERROR,
  payload: { error, action },
});

export const apiErrorRemove = (error) => ({
  type: API_REQUEST_ERROR_REMOVE,
  payload: error,
});
