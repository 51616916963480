import React from 'react';
import PropTypes from 'prop-types';

import styles from './Main.module.scss';

const Main = ({ children }) => <div className={styles.main}>{children}</div>;

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
