export const FETCH_ENVIRONMENT = 'FETCH_ENVIRONMENT';
export const SET_ENVIRONMENT = 'SET_ENVIRONMENT';
export const FETCH_ENVIRONMENT_2 = 'FETCH_ENVIRONMENT_2';
export const SET_ENVIRONMENT_2 = 'SET_ENVIRONMENT_2';

export const fetchEnvironment = () => ({
  type: FETCH_ENVIRONMENT,
});

export const setEnvironment = (data) => ({
  type: SET_ENVIRONMENT,
  payload: data,
});

export const fetchEnvironment2 = () => ({
  type: FETCH_ENVIRONMENT_2,
});

export const setEnvironment2 = (data) => ({
  type: SET_ENVIRONMENT_2,
  payload: data,
});
