import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, PieChart as Chart, Pie, Cell } from 'recharts';

import { Loading } from 'components';

import { Legend, Tooltip } from '..';
import styles from './PieChart.module.scss';

const PieChart = (props) => {
  const colors = ['#103E68', '#E1EBF4'];

  const render = () => {
    switch (true) {
      case props.loading:
        return <Loading />;

      case props.noData:
      // TODO: temporaraly removed for demo return <NoData />;

      // eslint-disable-next-line: no-fallthrough
      default:
        return (
          <Chart>
            <Pie
              dataKey="value"
              data={props.data}
              cx="50%"
              cy="50%"
              fill={colors[0]}
              innerRadius="59%"
              outerRadius="100%"
              startAngle={-90}
              endAngle={270}
            >
              {props.data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index] || undefined} />
              ))}
            </Pie>
            <Legend />
            <Tooltip unit={props.unit} units={props.units} />
          </Chart>
        );
    }
  };

  return (
    <div className={styles.pieChart}>
      <ResponsiveContainer height={350}>{render()}</ResponsiveContainer>
    </div>
  );
};

PieChart.PieChart = {
  loading: PropTypes.bool.isRequired,
  data: PropTypes.array,
  unit: PropTypes.string,
  noData: PropTypes.bool,
};

PieChart.defaultProps = {
  unit: '',
  noData: false,
};

export default PieChart;
