import { applyMiddleware, createStore, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import reducers from './reducers';
import {
  apiMdl,
  environmentMdl,
  environment2Mdl,
  electricityMdl,
  co2Mdl,
  cyclistsMdl,
  gasMdl,
  packagesMdl,
  rodentsMdl,
  trafficMdl,
  waterMdl,
} from './middleware';

export const configureStore = (preloadedState) => {
  const composeFn = process.env.NODE_ENV === 'production' ? compose : composeWithDevTools;

  const middlewares = [
    thunk,
    apiMdl,
    environmentMdl,
    environment2Mdl,
    electricityMdl,
    co2Mdl,
    cyclistsMdl,
    gasMdl,
    packagesMdl,
    rodentsMdl,
    trafficMdl,
    waterMdl,
  ];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeFn(...enhancers);

  const store = createStore(reducers, preloadedState, composedEnhancers);

  return store;
};
