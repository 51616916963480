import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { routerPaths } from 'components';
import * as icon from 'components/ui/Icon';

import styles from './Meter.module.scss';

const Meter = (props) => {
  let TypeIcon;
  let to = '/';
  const cName = [styles.container, styles[props.type]];

  switch (props.type) {
    case 'light':
      TypeIcon = icon.PinLight;
      to = routerPaths.lights.path;
      break;

    case 'air':
      TypeIcon = icon.PinAir;
      to = routerPaths.air.path;
      break;

    case 'traffic':
    case 'mega':
      TypeIcon = icon.PinSpeed;
      to = routerPaths.traffic.path;
      break;

    case 'electricity':
      TypeIcon = icon.PinElectricity;
      to = routerPaths.electricity.path;
      break;

    case 'rodent':
      TypeIcon = icon.PinRodent;
      to = routerPaths.rodent.path;
      break;

    case 'cycling':
      TypeIcon = icon.PinCycling;
      to = routerPaths.cycling.path;
      break;

    case 'water':
      TypeIcon = icon.PinWater;
      to = routerPaths.water.path;
      break;

    case 'co2':
      TypeIcon = icon.PinCo2;
      to = routerPaths.co2.path;
      break;

    case 'package':
      TypeIcon = icon.PinPackages;
      to = routerPaths.package.path;
      break;

    case 'gas':
      TypeIcon = icon.PinGas;
      to = routerPaths.gas.path;
      break;

    default:
      TypeIcon = icon.PinGeneric;
      to = routerPaths.home.path;
      break;
  }

  return (
    <Link to={to} className={cName.join(' ')}>
      <TypeIcon />
    </Link>
  );
};

Meter.propTypes = {
  type: PropTypes.string,
};

export default Meter;
