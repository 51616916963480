export const FETCH_CO2 = 'FETCH_CO2';
export const GOT_CO2 = 'GOT_CO2';
export const APPEND_CO2 = 'APPEND_CO2';
export const SET_CO2 = 'SET_CO2';

export const fetchCo2 = () => ({
  type: FETCH_CO2,
});

export const gotCo2 = (data) => ({
  type: GOT_CO2,
  payload: data,
});

export const appendCo2 = (data) => ({
  type: APPEND_CO2,
  payload: data,
});

export const setCo2 = (data) => ({
  type: SET_CO2,
  payload: data,
});
