export const FETCH_AIR = 'FETCH_AIR';
export const APPEND_AIR = 'APPEND_AIR';
export const SET_AIR = 'SET_AIR';

export const fetchAir = () => ({
  type: FETCH_AIR,
});

export const appendAir = (data) => ({
  type: APPEND_AIR,
  payload: data,
});

export const setAir = (data) => ({
  type: SET_AIR,
  payload: data,
});
