export const FETCH_RODENTS = 'FETCH_RODENTS';
export const GOT_RODENTS = 'GOT_RODENTS';
export const APPEND_RODENTS = 'APPEND_RODENTS';
export const SET_RODENTS = 'SET_RODENTS';

export const fetchRodents = () => ({
  type: FETCH_RODENTS,
});

export const gotRodents = (data) => ({
  type: GOT_RODENTS,
  payload: data,
});

export const appendRodents = (data) => ({
  type: APPEND_RODENTS,
  payload: data,
});

export const setRodents = (data) => ({
  type: SET_RODENTS,
  payload: data,
});
