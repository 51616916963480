import moment from 'moment';
import { selectByTime as byTime } from 'redux/selectors';

export function selectedData(day, predictionForDay, time, interval = 900, predictUnavailable = false) {
  const currentTime = moment().unix();
  const selectedTime = moment(time).unix();

  const realData = byTime(day, time, interval);
  const predictedData = byTime(predictionForDay, time, interval);

  if (predictUnavailable) {
    const isPrediction = selectedTime > currentTime && realData.length;

    return [isPrediction, predictedData];
  }

  if (selectedTime < currentTime) return [false, realData];

  return [true, predictedData];
}

export function emptyData(line, key, selectedTime = 0) {
  if (moment(selectedTime).unix() > moment().unix()) return false;

  return !line.some((row) => row[key] !== undefined);
}

export function emptyWeekData(line, key, selectedTime = 0) {
  if (moment(selectedTime).unix() > moment().unix()) return false;

  return !line.some((row) => row[key] !== 0);
}

export function pluralize(number, args) {
  switch (number) {
    case 1:
      return args[0];
    case 2:
      return args[1];
    case 3:
      return args[2];
    case 4:
      return args[3];
    default:
      return args[4];
  }
}
