import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import 'moment/locale/sl';

import styles from './TimePicker.module.scss';

const TimePicker = (props) => {
  const secondsInDay = 86400; // 24h in seconds
  const step = props.step || 900; // 15min in seconds
  const [marks, setMarks] = useState({});
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setMarks({
          0: '00:00',
          7200: '02:00',
          14400: '04:00',
          21600: '06:00',
          28800: '08:00',
          36000: '10:00',
          43200: '12:00',
          50400: '14:00',
          57600: '16:00',
          64800: '18:00',
          72000: '20:00',
          79200: '22:00',
          86400: '24:00',
        });
      } else {
        setMarks({
          0: '00:00',
          14400: '04:00',
          28800: '08:00',
          43200: '12:00',
          57600: '16:00',
          72000: '20:00',
          86400: '24:00',
        });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  useEffect(() => {
    const time = moment(props.start).locale('sl');
    let defaultValue = time.hour() * 3600 + time.minute() * 60;

    defaultValue -= defaultValue % step;

    setCurrentValue(defaultValue);
  }, [props.start, step]);

  let delayChange;
  const handleChange = (value) => {
    const newValue = value === secondsInDay ? secondsInDay - 1 : value;

    setCurrentValue(newValue);
    clearTimeout(delayChange);

    delayChange = setTimeout(() => {
      props.onChange(newValue * 1000);
    }, 500);
  };

  return (
    <div className={styles.container}>
      <Slider
        onChange={handleChange}
        className={styles.timePicker}
        min={0}
        max={secondsInDay}
        step={step}
        marks={marks}
        value={currentValue}
      />
    </div>
  );
};

TimePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  step: PropTypes.number,
};

export default TimePicker;
