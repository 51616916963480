import { FETCH_ENVIRONMENT_2, SET_ENVIRONMENT_2, fetchAir, setAir, setMeters } from '../../actions';

// Breaks environment into separate stores
export const environment2Mdl = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === FETCH_ENVIRONMENT_2) {
    dispatch(fetchAir());
  }

  const makeMeterId = (location) => `meter-at-${location.latitude}-${location.longitude}`;

  if (action.type === SET_ENVIRONMENT_2) {
    /*
     * Transform data to common format
     */
    const normalizedMeters = action.payload.sensors.map((sensor) => ({
      ...sensor,
      meter: makeMeterId(sensor.location),
    }));

    const airData = normalizedMeters.map((meter) => ({
      ...meter,
      readings: meter.readings.map((reading) => ({
        timestamp: reading.timestamp,
        concentrationOfNO2: reading.concentrationOfNO2,
        concentrationOfO3: reading.concentrationOfO3,
        concentrationOfPM010: reading.concentrationOfPM010,
        concentrationOfPM025: reading.concentrationOfPM025,
        concentrationOfPM100: reading.concentrationOfPM100,
        relativeAirHumidity: reading.relativeAirHumidity,
        airTemperature: reading.airTemperature,
        airPressure: reading.airPressure,
        noise: reading.noise,
        windDirection: reading.windDirection,
        windSpeed: reading.windSpeed,
      })),
    }));

    const metersDataAir = normalizedMeters.map((meter) => ({
      meter: meter.meter,
      location: meter.location,
      type: 'air',
    }));

    dispatch(setAir(airData));
    dispatch(setMeters(metersDataAir));
  }
};
