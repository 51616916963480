import { sortBy } from 'utils';

/**
 *
 * @param {array} meters - use selectMeters()
 */
export const selectElectricitySumByTime = (meters) => {
  if (!meters.length) return meters;

  const allReadings = sortBy(
    meters.reduce((readings, meter) => readings.concat(meter.readings), []),
    'timestamp',
  );

  const sumByTimestamp = allReadings.reduce((sum, reading) => {
    const duplicate = sum.findIndex((r) => reading.timestamp === r.timestamp);

    if (duplicate === -1) {
      sum.push({ ...reading });
    } else {
      sum[duplicate].value += reading.value;
    }

    return sum;
  }, []);

  return [
    {
      interval: undefined,
      location: undefined,
      meter: 'SUM',
      readings: sumByTimestamp,
    },
  ];
};

/**
 *
 * @param {array} meters - use selectMeters()
 * @param {string} timestamp  - any timestamp in the day
 */
export const selectElectricityTotalConsumptionPerMeter = (meters) => {
  if (!meters.length) return [0];

  const totalPerMeter = meters.map((meter) => {
    const sum = meter.readings.reduce((s, reading) => s + reading.value, 0);

    return {
      ...meter,
      readings: [
        {
          value: sum,
        },
      ],
    };
  });

  return totalPerMeter;
};

/**
 *
 * @param {array} meters - use selectMeters()
 * @param {string} key
 */
export const selectElectricityAvgConsumptionPerMeter = (meters, key = 'value') => {
  if (!meters.length) return [0];

  return meters.map((meter) => {
    const sum = meter.readings.reduce((s, reading) => {
      const total = s + reading[key] || 0;

      return total;
    }, 0);

    const avg = meter.readings.length ? sum / meter.readings.length : 0;

    return {
      ...meter,
      readings: [
        {
          [key]: avg,
        },
      ],
    };
  });
};

export * from './composed';
