import React from 'react';
import { Close } from 'components';

import styles from './AboutBox.module.scss';

const AboutBox = (props) => {
  const cNames = [styles.backdrop, props.visible ? styles.visible : styles.hidden];

  const noClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={cNames.join(' ')} onClick={props.toggle}>
      <div className={styles.container} onClick={noClick}>
        <h1>Pametna mlaka</h1>

        <div className={styles.close} onClick={props.toggle}>
          <Close />
        </div>

        <div className={styles.logos}>
          <a href="https://www.kranj.si/" target="_blank" rel="noopener noreferrer">
            <img src="/logo/mokranj.svg" alt="MO Kranj" />
          </a>
          <a href="https://3fs.si" target="_blank" rel="noopener noreferrer">
            <img src="/logo/3fs.svg" alt="3fs" />
          </a>

          <a href="https://www.iskraemeco.com/sl/" target="_blank" rel="noopener noreferrer">
            <img src="/logo/iskraemeco.svg" alt="Iskraemeco" />
          </a>
          <a href="http://www.vigred-elektro.si/" target="_blank" rel="noopener noreferrer">
            <img src="/logo/vigred.svg" alt="Vigred" />
          </a>
          <a href="https://www.domplan.si/" target="_blank" rel="noopener noreferrer">
            <img src="/logo/domplan.svg" alt="Domplan" />
          </a>
          <a href="https://www.elektro-gorenjska.si/" target="_blank" rel="noopener noreferrer">
            <img src="/logo/elektrogorenjska.svg" alt="Elektro gorenjska" />
          </a>
          <a href="https://www.riko.si/si/" target="_blank" rel="noopener noreferrer">
            <img src="/logo/riko.svg" alt="riko" />
          </a>
          <a href="https://www.komunala-kranj.si/" target="_blank" rel="noopener noreferrer">
            <img src="/logo/komunalakranj.svg" alt="Komunala Kranj" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default AboutBox;
