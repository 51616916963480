import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './Button.module.scss';

const Button = ({ to, children, variant }) => {
  const cName = [styles.button, variant ? styles[variant] : styles.neutral];

  return (
    <Link to={to} className={cName.join(' ')}>
      <span className={styles.label}>{children}</span>
    </Link>
  );
};

Button.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Button;
