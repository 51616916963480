import React from 'react';
import { connect } from 'react-redux';
import { Header } from '..';
import { toggleAboutBox } from 'redux/actions';

import styles from './Page.module.scss';

const Page = (props) => (
  <>
    <Header toggleAboutBox={props.toggleAboutBox} aboutBox={props.aboutBox} />

    <div className={`${props.className} ${styles.page}`}>{props.children}</div>
  </>
);

const mapStateToProps = (state) => ({
  aboutBox: state.ui.aboutBox,
});

const mapDispatchToProps = (dispatch) => ({
  toggleAboutBox: (e) => {
    e.stopPropagation();
    dispatch(toggleAboutBox());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Page);
